//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import * as Utils from "eai-configurator-ui/components/Utils";
import Tooltip from "@material-ui/core/Tooltip";
import AsyncComboBox from "eai-configurator-ui/components//base/AsyncComboBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  error: {
    color: "red",
  },
  checkbox: {
    paddingLeft: 0,
  },
}));

const UserEditDialog = ({
                          handleSuccess,
                          handleClose,
                          companyId,
                          isFileProfile,
                          isLiveProfile,
                          user: inputUser,
                        }) => {

  const classes = useStyles();

  const [user, setUser] = useState({...inputUser});
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const setEmail = (event) => {
    if (isFileProfile) {
      const userClone = {...user};
      userClone.email = event.target.value;
      setUser(userClone);
    }
  };
  const setPassword = (event) => {
    if (isFileProfile) {
      const userClone = {...user};
      userClone.password = event.target.value;
      setUser(userClone);
    }
  };
  const setTeams = (teams) => {
    const userClone = {...user};
    userClone.teams = teams;
    setUser(userClone);
  };
  const setIsCompanySuperuser = (event) => {
    const userClone = {...user};
    userClone.superuser = event.target.checked;
    setUser(userClone);
  };
  const setEnabled = (event) => {
    const userClone = {...user};
    userClone.enabled = event.target.checked;
    setUser(userClone);
  };

  const isEditingExistingUser = () => {
    return Boolean(inputUser);
  };

  const handleApply = () => {
    setSubmitted(true);

    const details = {
      email: user.email,
      password: user.password,
    };
    if (companyId) {
      details.companyId = companyId;
    }
    if (user.superuser) {
      details.superuser = user.superuser;
    }
    if (user.enabled) {
      details.enabled = user.enabled;
    }
    details.teams = user.teams;
    if (!details.teams) {
      details.teams = [];
    }

    Utils.fetchData(
      "scrubber/user",
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: Utils.createFormBody(details),
      },
      false,
      false
    )
      .catch((e) => {
        setErrorMessage("User edit failed");
        setSubmitted(false);
      })
      .then((response) => {
        if (response && response.ok) {
          handleClose();
          setSubmitted(false);
          handleSuccess(user);
        } else {
          setErrorMessage("User edit failed");
        }
      });
  };

  const userValidators = ["required"];
  const userValidatorMessages = ["This field is required"];
  if (isFileProfile) {
    userValidators.push("isEmail");
    userValidatorMessages.push("Email is not valid");
  }

  const isEditing = isEditingExistingUser();
  let teamsUrl = "scrubber/teams";
  if (companyId) {
    teamsUrl += "?companyId=" + companyId;
  }
  return (
    <div>
      <CloseableDialog
        title={isEditing ? "Edit User" : "Create New User"}
        open
        maxWidth="sm"
        fullWidth
        handleClose={handleClose}
      >
        <ValidatorForm onSubmit={handleApply} style={{width: "100%"}}>
          <DialogContent dividers>
            <TextValidator
              className={classes.textField}
              label="Username"
              type="text"
              disabled={isLiveProfile || isEditingExistingUser()}
              variant="standard"
              value={user.email}
              onChange={setEmail}
              autoComplete="username"
              id="user-edit-username"
              validators={userValidators}
              errorMessages={userValidatorMessages}
            />
            {isFileProfile &&
              <TextValidator
                className={classes.textField}
                label="Password"
                type="password"
                variant="standard"
                value={user.password}
                autoComplete="current-password"
                onChange={setPassword}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            }
            <AsyncComboBox
              url={teamsUrl}
              textFieldProps={{
                label: "Teams",
                fullWidth: true,
                helperText: "Users in the same team can share definitions",
              }}
              value={user.teams || []}
              onChange={(event, value) => setTeams(value)}
              freeSolo
              multiple
              autoSelect={false}
            />
            {isFileProfile &&
              <Tooltip title="Company superusers can manage other users">
                <FormControlLabel
                  value="superuser"
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={user.superuser}
                      onChange={setIsCompanySuperuser}
                      color="primary"
                    />
                  }
                  label="Company Superuser"
                  labelPlacement="end"
                />
              </Tooltip>
            }
            <Tooltip title="The user can only login if he/ she is enabled">
              <FormControlLabel
                value="enabled"
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={user.enabled}
                    onChange={setEnabled}
                    color="primary"
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
            </Tooltip>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" disabled={submitted}>
              {submitted ? "Applying..." : "Apply"}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </CloseableDialog>
    </div>
  );
};
export default UserEditDialog;
