//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import {
  createCheckBoxEditorColumn,
  createComboBoxEditorColumn,
  createTextEditorColumn,
  createTextTypeAheadEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import "../../../main.css";
import ConfigurationDictionaryTable from "src/components/scrubber/code/ConfigurationDictionaryTable";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const useStyles = makeStyles((theme) => ({
  expandedPanel: {
    backgroundColor: "#fbfbfb",
  },
}));
const ConfigurationCodeTable = ({path, scope, isLiveScrubberProfile}) => {
  const classes = useStyles();
  const [searchVisible, setSearchVisible] = React.useState(false);
  const url = isLiveScrubberProfile
    ? (row) => {
      let suggestionsUrl =
        `scrubber/codes?subjectArea=` + scope.toUpperCase();
      if (row.scope) {
        suggestionsUrl += "&activityScope=" + row.scope;
      }
      if (row.projectId) {
        suggestionsUrl += "&objectId=" + encodeURIComponent(row.projectId);
      }
      return suggestionsUrl;
    }
    : null;
  const columns = [
    createTextEditorColumn(path + ":codeType:name", searchVisible),
    createComboBoxEditorColumn(path + ":codeType:action", searchVisible),
    createTextTypeAheadEditorColumn(
      path + ":codeType:newName",
      searchVisible,
      url
    ),
  ];

  const isActivity = scope === "Activity";
  if (isActivity) {
    columns.splice(
      1,
      0,
      createComboBoxEditorColumn(path + ":codeType:scope", searchVisible),
      createTextEditorColumn(path + ":codeType:projectId", searchVisible)
    );
    columns.push(
      createCheckBoxEditorColumn(path + ":codeType:rolldown", searchVisible)
    );
  }
  const expandRow = {
    renderer: (row) => (
      <div>
        <ConfigurationCheckBox
          path={`${path}:codeType[${row.dataIndex}]removeIllegalValues`}
        />
        <ConfigurationDictionaryTable
          codeTypePath={`${path}:codeType[${row.dataIndex}]`}
          codeTypeRow={row}
          subjectArea={scope}
          isLiveScrubberProfile={isLiveScrubberProfile}
        />
      </div>
    ),
    expandHeaderColumnRenderer: ({isAnyExpands}) => (
      <Tooltip title="Dictionary">
        <div>+</div>
      </Tooltip>
    ),
    expandColumnRenderer: ({expanded}) => {
      if (expanded) {
        return <b>-</b>;
      }
      return <b>+</b>;
    },
    className: classes.expandedPanel,
    showExpandColumn: true,
    expandByColumnOnly: true,
  };
  return (
    <ScrubberConfigurationTable
      path={path}
      childKey={"codeType"}
      columns={columns}
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      expandRow={expandRow}
      allowBulkEdit
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};

export default ConfigurationCodeTable;
