//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ErrorMessageDialog from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";
import SignUpDialog from "src/components/scrubber/dialog/SignUpDialog";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));
const SignUpButton = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [errorNotification, setErrorNotification] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const closeErrorNotification = () => {
    if (errorNotification) {
      const notificationClone = { ...errorNotification };
      notificationClone.open = false;
      setErrorNotification(notificationClone);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ErrorMessageDialog
        open={errorNotification && errorNotification.open}
        handleClose={closeErrorNotification}
        title={errorNotification && errorNotification.title}
        description={errorNotification && errorNotification.message}
        maxWidth="sm"
        fullWidth
      />
      <CloseableDialog
        dividers
        open={open}
        title={"Sign Up"}
        maxWidth="md"
        fullWidth
        handleClose={handleClose}
      >
        <DialogContent>
          <SignUpDialog />
        </DialogContent>
      </CloseableDialog>

      <Button
        type="submit"
        fullWidth
        variant="outlined"
        color="secondary"
        className={classes.submit}
        onClick={handleOpen}
      >
        Sign up
      </Button>
    </div>
  );
};
export default SignUpButton;
