/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PatternAnyLetterCustomizationDialog from "./PatternAnyLetterCustomizationDialog";
import PatternAnyNumberCustomizationDialog from "./PatternAnyNumberCustomizationDialog";
import PatternAnyCharacterCustomizationDialog from "./PatternAnyCharacterCustomizationDialog";
import PatternSpecificTextCustomizationDialog from "./PatternSpecificTextCustomizationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    whiteSpace: "nowrap",
  },
  inputCenter: {
    textAlign: "center",
    color: "red",
  },
}));

const patternQuoteStart = "\\Q";
const patternQuoteEnd = "\\E";

const quotePattern = (pattern) => {
  return patternQuoteStart + pattern + patternQuoteEnd;
};

const getExampleValueForOption = (summaryCharacterSet, option) => {
  if (summaryCharacterSet) {
    const count = option.count;
    if (count === 0 || count > 10) {
      // Communicate unlimited number of digits
      return (
        summaryCharacterSet[0] +
        ".." +
        summaryCharacterSet[summaryCharacterSet.length - 1]
      );
    }
    return Array(count)
      .fill(0)
      .map((value, index) => summaryCharacterSet[index])
      .join("");
  }
};
const getQuantifierCount = (pattern) => {
  if (pattern) {
    if (pattern.startsWith("{")) {
      const closeBracketIndex = pattern.indexOf("}");
      if (closeBracketIndex > 0) {
        const digits = pattern.substring(1, closeBracketIndex);
        if (digits.length > 0) {
          if (!isNaN(digits)) {
            return {
              count: parseInt(digits),
              subPattern: pattern.substring(closeBracketIndex + 1),
            };
          }
        }
      }
    } else if (pattern.startsWith("*")) {
      // Indicates unlimited count
      return { count: 0, subPattern: pattern.substring(1) };
    }
  }
  // Default quantifier count
  return { count: 1, subPattern: pattern };
};

const patternAnyLetterOption = {
  id: "anyLetter",
  label: "Any Letter (A-Z)",
  caseSensitivity: "ignore",
  count: null,
  getPattern: function (caseSensitivity, count) {
    count = count || this.count;
    caseSensitivity = caseSensitivity || this.caseSensitivity;

    let pattern;
    if (caseSensitivity === "upper") {
      pattern = "[A-Z]";
    } else if (caseSensitivity === "lower") {
      pattern = "[a-z]";
    } else {
      pattern = "[[A-Z]|[a-z]]";
    }
    if (count !== null) {
      if (count === 0) {
        pattern += "*";
      } else if (count > 0) {
        pattern += "{" + count + "}";
      }
    }
    return pattern;
  },
  getSummary: function () {
    return (
      (this.count > 0 ? "Any " + this.count : "Unlimited number of") +
      " letter" +
      (this.count === 1 ? "" : "s") +
      (this.caseSensitivity === "lower" ? " (a-z)" : " (A-Z)")
    );
  },
  getExample: function () {
    let summaryCharacterSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (this.caseSensitivity === "lower") {
      summaryCharacterSet = summaryCharacterSet.toLowerCase();
    }
    return getExampleValueForOption(summaryCharacterSet, this);
  },
  parse: function (pattern) {
    if (pattern) {
      const option = { ...this };
      const result = ["ignore", "lower", "upper"].reduce(
        (current, caseSensitivity) => {
          if (!current) {
            let basePattern = this.getPattern(caseSensitivity);
            if (pattern.startsWith(basePattern)) {
              const { count, subPattern } = getQuantifierCount(
                pattern.substring(basePattern.length, pattern.length)
              );
              option.count = count;
              option.caseSensitivity = caseSensitivity;
              option.label = option.getSummary();
              return { option: option, subPattern: subPattern };
            }
          }
          return current;
        },
        null
      );
      if (result) {
        return result;
      }
    }
    return { option: null, subPattern: pattern };
  },
};

const patternAnyNumberOption = {
  id: "anyNumber",
  label: "Any Number (0-9)",
  count: null,
  getPattern: function () {
    let pattern = "[0-9]";
    if (this.count !== null) {
      if (this.count === 0) {
        pattern += "*";
      } else if (this.count > 0) {
        pattern += "{" + this.count + "}";
      }
    }
    return pattern;
  },
  getSummary: function () {
    return (
      (this.count > 0 ? "Any " + this.count : "Unlimited number of") +
      " digit" +
      (this.count === 1 ? "" : "s") +
      " (0-9)"
    );
  },
  getExample: function () {
    return getExampleValueForOption("0123456789", this);
  },
  parse: function (pattern) {
    if (pattern) {
      const option = { ...this };
      let basePattern = this.getPattern();
      if (pattern.startsWith(basePattern)) {
        const { count, subPattern } = getQuantifierCount(
          pattern.substring(basePattern.length, pattern.length)
        );
        option.count = count;
        option.label = option.getSummary();
        return { option: option, subPattern: subPattern };
      }
    }
    return { option: null, subPattern: pattern };
  },
};
const patternAnyCharacterOption = {
  id: "anyCharacter",
  label: "Any Character",
  count: null,
  getPattern: function () {
    let pattern = ".";
    if (this.count !== null) {
      if (this.count === 0) {
        pattern += "*";
      } else if (this.count > 0) {
        pattern += "{" + this.count + "}";
      }
    }
    return pattern;
  },
  getSummary: function () {
    return (
      (this.count > 0 ? "Any " + this.count : "Unlimited number of") +
      " character" +
      (this.count === 1 ? "" : "s")
    );
  },
  getExample: function () {
    return getExampleValueForOption("*************", this);
  },
  parse: function (pattern) {
    if (pattern) {
      const option = { ...this };
      let basePattern = this.getPattern();
      if (pattern.startsWith(basePattern)) {
        const { count, subPattern } = getQuantifierCount(
          pattern.substring(basePattern.length, pattern.length)
        );
        option.count = count;
        option.label = option.getSummary();
        return { option: option, subPattern: subPattern };
      }
    }
    return { option: null, subPattern: pattern };
  },
};
const patternSpecificTextOption = {
  id: "specificText",
  label: "Specific Text",
  text: null,
  getPattern: function () {
    return quotePattern(this.text);
  },
  getSummary: function () {
    return 'Specific Text: "' + this.text + '"';
  },
  getExample: function () {
    return this.text;
  },
  parse: function (pattern) {
    if (pattern) {
      const option = { ...this };
      if (pattern.startsWith(patternQuoteStart)) {
        const endIndex = pattern.indexOf(patternQuoteEnd);
        if (endIndex > 0) {
          option.text = pattern.substring(patternQuoteStart.length, endIndex);
          option.label = option.getSummary();
          const subPattern = pattern.substring(
            endIndex + patternQuoteEnd.length
          );
          return { option: option, subPattern: subPattern };
        }
      }
    }
    return { option: null, subPattern: pattern };
  },
};

const possibleOptions = [
  patternAnyLetterOption,
  patternAnyNumberOption,
  patternAnyCharacterOption,
  patternSpecificTextOption,
];

const isPatternSimple = (pattern) => {
  return parseOptionsFromPattern(pattern).length > 0;
};
const parseOptionsFromPattern = (pattern) => {
  const options = [];
  while (pattern) {
    const result = possibleOptions.reduce((current, option) => {
      if (current) {
        return current;
      }
      current = option.parse(pattern);
      if (current && current.option) {
        return current;
      }
      return null;
    }, null);
    if (!result || !result.option) {
      return [];
    }
    options.push(result.option);
    pattern = result.subPattern;
  }
  return options;
};

function SimplePatternInputField({
  handlePatternChange,
  pattern: initialPattern,
  isInsideGrid,
}) {
  const classes = useStyles();
  const [
    isPendingCustomizationOption,
    setIsPendingCustomizationOption,
  ] = React.useState(false);
  const [renderFlag, setRenderFlag] = React.useState(false);
  const pendingCustomizationOption = React.useRef(null);
  const selectedOptions = React.useRef(parseOptionsFromPattern(initialPattern));

  useEffect(() => {
    if (!isPendingCustomizationOption) {
      const pattern = selectedOptions.current
        .map((option) => option.getPattern())
        .join("");
      if (handlePatternChange) {
        handlePatternChange(pattern);
      }
    }
  }, [isPendingCustomizationOption, renderFlag, handlePatternChange]);

  const getPossibleOptions = () => {
    return possibleOptions.map((option) => ({ ...option }));
  };

  const onChange = (event, values, reason) => {
    if (reason === "select-option") {
      pendingCustomizationOption.current = values[values.length - 1];
      setIsPendingCustomizationOption(true);
    }
    selectedOptions.current = values;
    forceRender();
  };

  const forceRender = () => {
    setRenderFlag(!renderFlag);
  };

  const handleAnyLetterCustomization = (letterCount, caseSensitivity) => {
    const option = pendingCustomizationOption.current;
    option.count = letterCount;
    option.caseSensitivity = caseSensitivity;
    option.label = option.getSummary();
    setIsPendingCustomizationOption(false);
    pendingCustomizationOption.current = null;
  };

  const handleAnyNumberCustomization = (digitCount) => {
    const option = pendingCustomizationOption.current;
    option.count = digitCount;
    option.label = option.getSummary();
    setIsPendingCustomizationOption(false);
    pendingCustomizationOption.current = null;
  };

  const handleAnyCharacterCustomization = (digitCount) => {
    const option = pendingCustomizationOption.current;
    option.count = digitCount;
    option.label = option.getSummary();
    setIsPendingCustomizationOption(false);
    pendingCustomizationOption.current = null;
  };

  const handleSpecificTextCustomization = (text) => {
    const option = pendingCustomizationOption.current;
    option.text = text;
    option.label = option.getSummary();
    setIsPendingCustomizationOption(false);
    pendingCustomizationOption.current = null;
  };

  const optionsExample = selectedOptions.current
    .map((option) => option.getExample())
    .join("");
  return (
    <div className={classes.root}>
      <PatternAnyLetterCustomizationDialog
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.id === "anyLetter"
        }
        handleApplyChanges={handleAnyLetterCustomization}
      />
      <PatternAnyNumberCustomizationDialog
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.id === "anyNumber"
        }
        handleApplyChanges={handleAnyNumberCustomization}
      />
      <PatternAnyCharacterCustomizationDialog
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.id === "anyCharacter"
        }
        handleApplyChanges={handleAnyCharacterCustomization}
      />
      <PatternSpecificTextCustomizationDialog
        open={
          isPendingCustomizationOption &&
          pendingCustomizationOption.current.id === "specificText"
        }
        handleApplyChanges={handleSpecificTextCustomization}
      />
      <Autocomplete
        multiple
        id="simple-pattern"
        filterOptions={(options, state) => {
          return getPossibleOptions();
        }}
        defaultValue={selectedOptions.current}
        options={possibleOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={isInsideGrid ? "standard" : "outlined"}
            label="Simple Pattern"
            placeholder={isInsideGrid ? "" : "Followed by..."}
            helperText={
              !isInsideGrid &&
              optionsExample &&
              "Example pattern: " + optionsExample
            }
          />
        )}
      />
    </div>
  );
}

export { isPatternSimple };
export default SimplePatternInputField;
