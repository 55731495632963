//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createCheckBoxEditorColumn,
  createComboBoxEditorColumn,
  createTextEditorColumn,
  createTextTypeAheadEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const ConfigurationUDFTable = ({
  path,
  subjectArea,
  isLiveScrubberProfile,
}) => {
  const [searchVisible, setSearchVisible] = React.useState(false);
  const suggestionsUrl = isLiveScrubberProfile
    ? (row) => {
        let suggestionsUrl =
          `scrubber/udfs?subjectArea=` + subjectArea.toUpperCase();
        if (row.dataType) {
          suggestionsUrl += "&dataType=" + row.dataType;
        }
        return suggestionsUrl;
      }
    : null;
  const columns = [
    createTextEditorColumn(path + ":udf:name", searchVisible),
    createComboBoxEditorColumn(path + ":udf:subjectArea", searchVisible),
    createComboBoxEditorColumn(path + ":udf:dataType", searchVisible),
    createComboBoxEditorColumn(path + ":udf:action", searchVisible),
    createTextTypeAheadEditorColumn(
      path + ":udf:newName",
      searchVisible,
      suggestionsUrl
    ),
  ];
  return (
    <ScrubberConfigurationTable
      path={path}
      childKey={"udf"}
      columns={columns}
      allowBulkEdit
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};

export default ConfigurationUDFTable;
