//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import Divider from "@material-ui/core/Divider";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import ConfigurationCodeTable from "src/components/scrubber/code/ConfigurationCodeTable";

const CodeTypes = ({path, isLiveScrubberProfile}) => {
  const scope = path.slice(path.lastIndexOf("codes") + 5);
  return (
    <React.Fragment>
      <React.Fragment>
        <Label title="Code Type/Dictionary Validation"/>
        <p/>
      </React.Fragment>
      <p/>
      <ConfigurationSwitch path={path + ":enabled"}/>
      <Divider light style={{marginTop: 8}}/>
      <ConfigurationCheckBox path={path + ":removeExtraCodeTypes"}/>
      <p/>
      <ConfigurationCodeTable
        path={path + ":codeTypes"}
        scope={scope}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default CodeTypes;
