//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import ConfigurationTextField from "eai-configurator-ui/components/configuration/base/ConfigurationTextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const SimpleScrubEntry = ({path, label, isLiveScrubberProfile, showRolldownOption}) => {
  return (
    <>
      <Grid item xs={4}>
        <Label title={label}/>
      </Grid>
      <Grid item xs={2}>
        <ConfigurationCheckBox path={path + ":doNotImport"}/>
        {showRolldownOption && <ConfigurationCheckBox path={path + ":rolldownOption"}/>}
      </Grid>
      <Grid item xs={6}>
        <ConfigurationTextField path={path + ":prefix"}/>
      </Grid>
      <Grid item xs={12}>
        <Divider light style={{marginTop: -6}}/>
      </Grid>
    </>
  );
};

export default SimpleScrubEntry;
