//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ErrorMessageDialog from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";
import { useSessionInfo } from "eai-configurator-ui/components/SessionInfo";
import * as Utils from "eai-configurator-ui/components/Utils";

const styles = (theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
});
function iframe() {
  return {
    __html:
      '<iframe src="./static/terms/EULA.htm" width="100%" height="100%"></iframe>',
  };
}

const ScrubberWelcomeOverview = ({ classes }) => {
  const [sessionInfo] = useSessionInfo();
  const [eulaOpen, setEulaOpen] = React.useState(true);
  const handleApproveEula = () => {
    Utils.fetchData("scrubber/approveEula", { method: "post" }, false, false);
    setEulaOpen(false);
  };

  return (
    <React.Fragment>
      <ErrorMessageDialog
        open={!sessionInfo.props.eulaConfirmed && eulaOpen}
        handleClose={handleApproveEula}
        title={"Terms and conditions"}
        description={
          <div style={{ height: "500px" }} dangerouslySetInnerHTML={iframe()} />
        }
        maxWidth="lg"
        fullWidth
        warning
        hideX
        closeButtonLabel={"Agree"}
      />
      <div className={classes.caption}>
        <h2>About P6-Scrubber</h2>
        <p>
          P6-Scrubber is a tool built by Emerald Associates, Inc that can read,
          validate or modify an XER or XML prior to importing it to Primavera P6.
        </p>{" "}
        <p>
          P6-Scrubber gives you control to manage data from imports by allowing
          you to keep, remap and clean data at the Project, WBS, Activity
          levels. Global data, such as Calendars, Cost Accounts, Roles and
          Resources can be also be controlled by the tool.
        </p>{" "}
        <p>
          XER or XML files can be read into P6-Scrubber to view what type of data
          exists. This data can be used to create and save Scrubber Definitions
          to run on future files or definitions can be created from scratch.
        </p>{" "}
        <p>
          After scrubbing a file, a report is created of the actions taken by
          P6-Scrubber and a clean XER or XML file is produced.
        </p>{" "}
        <p>
          P6-Scrubber also gives you the option to create a Notebook of the
          change report and/or indicator flags to import with your XER with the
          changes P6-Scrubber made.
        </p>
        <p />
      </div>
    </React.Fragment>
  );
};
export default withStyles(styles)(ScrubberWelcomeOverview);
