//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import FilesIcon from "@material-ui/icons/FileCopy";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ScrubberUpload from "../ScrubberUpload";
import * as Utils from "eai-configurator-ui/components/Utils";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import {HtmlString} from "eai-configurator-ui/components//Utils";
import ErrorMessageDialog, {useErrorMessageDialog,} from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";

const ImportFilesButton = ({onApply}) => {
  const [open, setOpen] = React.useState(false);
  const [isImportRequestPending, setImportRequestPending] = React.useState(
    false
  );
  const [
    importConfigurationValues,
    setImportConfigurationValues,
  ] = React.useState(null);
  const [importSummary, setImportSummary] = React.useState(null);
  const [importedProjects, setImportedProjects] = React.useState( "None");
  const [errorMessage, setErrorMessage] = useErrorMessageDialog();

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    Config.setConfigurationValues(importConfigurationValues);
    handleClose();
    if (onApply) {
      onApply(importedProjects);
    }
  };

  const handleOpen = () => {
    setImportSummary(null);
    setOpen(true);
  };

  const upload = (file) => {
    setImportRequestPending(true);
    const data = new FormData();
    data.append("file", file);
    data.append(
      "values",
      JSON.stringify({
        values: Config.buildConfigurationValueDtos(),
      })
    );
    Utils.fetchData(
      "scrubber/mergeConfigurationWithFile",
      {
        method: "POST",
        body: data,
      },
      false
    )
      .then((data) => {
        setImportConfigurationValues(data.values);
        setImportSummary(data.summaryMessage.trim());
        setImportedProjects(data.importedProjects.trim());
        setImportRequestPending(false);
      })
      .catch((error) => {
        Utils.getErrorMessage(error, "Import failed.").then(setErrorMessage);
        setImportRequestPending(false);
      });
  };

  const handleSubmitFiles = (files) => {
    // upload and submit files
    for (let i = 0; i < files.length; i++) {
      upload(files[i]);
    }
    return true;
  };
  return (
    <div>
      <ErrorMessageDialog {...errorMessage} />
      <CloseableDialog
        open={open}
        title={"Import XER/ XML Files"}
        maxWidth="sm"
        fullWidth
        handleClose={handleClose}
      >
        <DialogContent dividers>
          Import codes, UDFs, calendars and cost accounts from uploaded file(s).
          <Stepper
            activeStep={importSummary !== null ? 1 : 0}
            orientation="vertical"
          >
            <Step key="upload">
              <StepLabel>Upload Files</StepLabel>
              <StepContent>
                <ScrubberUpload
                  handleSubmit={handleSubmitFiles}
                  submitButtonLabel={"Import"}
                  dropzoneOptions={{ multiple: false }}
                  isImportRequestPending={isImportRequestPending}
                />
              </StepContent>
            </Step>
            <Step key="upload">
              <StepLabel>Review imports</StepLabel>
              <StepContent>
                {importSummary ? (
                  <HtmlString text={importSummary} />
                ) : (
                  "No changes found"
                )}
              </StepContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleApply}
            color="primary"
            disabled={!importSummary}
          >
            Apply
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </CloseableDialog>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<FilesIcon />}
      >
        Import Files
      </Button>
    </div>
  );
};
export default ImportFilesButton;
