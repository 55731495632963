//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import * as Utils from "eai-configurator-ui/components/Utils";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 500,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  checkbox: {
    paddingLeft: 0,
  },
}));

export default function ManageUsersTable({
                                           setAlertMessage,
                                           refreshCounter,
                                           handleEditUser,
                                           handleDeleteUser,
                                           handleSetUserEnabled,
                                           isFileProfile,
                                           isLiveProfile,
                                           companyId,
                                         }) {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    populateTable();
  }, [refreshCounter]);

  const populateTable = () => {
    let url = "scrubber/users";
    if (companyId) {
      url += "?companyId=" + companyId;
    }
    Utils.fetchData(url)
      .then((data) => {
        setUsers(data.users);
      })
      .catch(function (error) {
        setAlertMessage({
          success: false,
          message:
            "Failed to fetch users." +
            (error.statusText ? " " + error.statusText : ""),
        });
      });
  };

  const setEnabled = (user, index) => (event) => {
    const usersClone = [...users];
    const userFromIndex = usersClone[index];
    userFromIndex.enabled = event.target.checked;
    usersClone[index] = userFromIndex;
    setUsers(usersClone);

    handleSetUserEnabled(user.email, event.target.checked);
  };

  const isTableEmpty = users.length === 0;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={clsx(classes.toolbar)}>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Users
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="Users">
            {!isTableEmpty && (
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Enabled</TableCell>
                  <TableCell align="center">Company Superuser</TableCell>
                  <TableCell align="center">Last Activity</TableCell>
                  <TableCell align="center">Scrubbed Files Count</TableCell>
                  <TableCell align="center">Scrubbed Projects Count</TableCell>
                  <TableCell align="center">Scrub Failures</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index} hover role="task" tabIndex={-1}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    <Checkbox
                      className={classes.checkbox}
                      checked={user.enabled}
                      onChange={setEnabled(user, index)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {String(user.superuser) === "true" ? "Yes" : "No"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {(user.lastActivityTime && user.lastActivityTime > 0) ? (new Date(user.lastActivityTime).toLocaleString()) : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {user.scrubbedFilesCount}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {user.scrubbedProjectsCount}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {user.scrubFailuresCount}
                  </TableCell>
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    <IconButton
                      onClick={() => {
                        handleEditUser(user);
                      }}
                    >
                      <EditIcon/>
                    </IconButton>
                    {
                      isFileProfile &&
                      <IconButton
                        onClick={() => {
                          handleDeleteUser(user);
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow style={{height: 53}}>
                  <TableCell>
                    <Typography align="center" color="textSecondary">
                      No users
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
