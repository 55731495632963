//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, {useState} from "react";
import Label from "eai-configurator-ui/components/base/Label";
import TreeView from "eai-configurator-ui/components/base/TreeView";

const ParentRoleSelection = ({path, isLiveScrubberProfile}) => {
  const [editableModuleNames, setEditableModuleNames] = useState(null);
  const [editedModuleName, setEditedModuleName] = useState(null);
  return (
    <>
      <Label title="Default Parent Role"/>
      <TreeView
        path={`scrubber:role:defaultParentRole`}
        url={"scrubber/roleTree"}
        handleEdit={setEditedModuleName}
        // selectedNodeIds={editableModules}
        storeFullNodes={true}
        editableNodeIdSet={editableModuleNames}
        radioTreeMode={true}
        startWithRootCollapsed={true}
      />
    </>
  );
};
export default ParentRoleSelection;
