//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationProjectCalendarTable from "src/components/scrubber/calendar/table/ConfigurationProjectCalendarTable";
import InfoBox from "eai-configurator-ui/components/alert/InfoBox";

const ProjectCalendarPage = ({ path, isLiveScrubberProfile }) => {
  const [infoOpen, setInfoOpen] = React.useState(true);
  return (
    <React.Fragment>
      <Label title="Project Calendars" />
      {infoOpen && (
        <InfoBox title={"Info"} handleClose={() => setInfoOpen(false)}>
          <li>
            Project calendars cannot be rolled down or modified. They are
            displayed below solely for informational purposes. It's useful to
            see them when importing a file.
          </li>
        </InfoBox>
      )}
      <p />
      <ConfigurationProjectCalendarTable
        path={path + ":project"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default ProjectCalendarPage;
