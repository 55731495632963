//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  expansionPanel: {
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.1)",
  },
  expandedPanelContent: {
    backgroundColor: "#FCFCFC",
    padding: 0,
  },
}));
const InfoPanel = ({ title, subtitle, items, initiallyExpanded }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(null);

  const handleExpandedPanelClick = (panel) => (event, isExpanded) => {
    setExpanded(expanded ? false : true);
  };
  return (
    <ExpansionPanel
      className={classes.expansionPanel}
      expanded={(expanded == null && initiallyExpanded) || expanded}
      onChange={handleExpandedPanelClick("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography variant="caption" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="caption" className={classes.secondaryHeading}>
          {subtitle}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expandedPanelContent}>
        <ul>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item} {index < items.length - 1 && <p />}
            </React.Fragment>
          ))}
        </ul>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
export default InfoPanel;
