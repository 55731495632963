//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const PatternSpecificTextCustomizationDialog = ({
  open,
  handleApplyChanges,
}) => {
  const [text, setText] = useState("");

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="pattern-dialog">Specific Text</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Summary: The provided text will be matched exactly <b>{text}</b>
        </DialogContentText>
        <p />
        <TextField
          size="medium"
          label={"Specific text"}
          type="text"
          variant="outlined"
          value={text}
          onChange={(event) => setText(event.target.value)}
          id="text"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleApplyChanges(text)}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PatternSpecificTextCustomizationDialog;
