//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createComboBoxEditorColumn,
  createTextEditorColumn,
  createTextTypeAheadEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const ConfigurationResourceTable = ({path, isLiveScrubberProfile}) => {
  const [searchVisible, setSearchVisible] = React.useState(false);
  const suggestionsUrl = isLiveScrubberProfile ? "scrubber/resources" : null;
  const columns = [
    createTextEditorColumn(path + ":resource:id", searchVisible),
    createTextEditorColumn(path + ":resource:name", searchVisible),
    createComboBoxEditorColumn(path + ":resource:action", searchVisible),
    createTextTypeAheadEditorColumn(
      path + ":resource:newId",
      searchVisible,
      suggestionsUrl,
      (options) => options.map((option) => option.id)
    ),
    createTextTypeAheadEditorColumn(
      path + ":resource:newName",
      searchVisible,
      suggestionsUrl,
      (options, row) =>
        options
          .filter((option) => option.id === row.newId)
          .map((option) => option.name)
    ),
  ];
  return (
    <ScrubberConfigurationTable
      path={path}
      childKey={"resource"}
      columns={columns}
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      allowBulkEdit
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};
export default ConfigurationResourceTable;
