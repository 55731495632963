//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import Divider from "@material-ui/core/Divider";
import SimpleScrubEntry from "src/components/scrubber/simple_scrub/SimpleScrubEntry";
import ConfigurationTextField from "eai-configurator-ui/components/configuration/base/ConfigurationTextField";
import Grid from "@material-ui/core/Grid";

const SimpleScrub = ({path, isLiveScrubberProfile}) => {
  return (
    <>
      <Label title="Simple Scrub"/>
      <p/>
      <ConfigurationSwitch path={path + ":enabled"}/>
      <Divider light style={{marginTop: 8}}/>
      <Grid container rowSpacing={0}>
        <Grid item xs={2}>
          <Label title={"Separator:"}/>
        </Grid>
        <Grid item xs={4}>
          <ConfigurationTextField label="Common prefix separator" path={path + ":separator"}/>
        </Grid>
      </Grid>
      <p/>
      <Grid container rowSpacing={0}>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={6}>
          <Label title={"Prefix"}/>
        </Grid>
        <SimpleScrubEntry label="Project code type" path={path + ":ProjectCodeType"}/>
        <SimpleScrubEntry label="Global activity code type" path={path + ":ActivityCodeType"} showRolldownOption={true}/>
        <SimpleScrubEntry label="Resource code type" path={path + ":ResourceCodeType"}/>
        <SimpleScrubEntry label="Role code type" path={path + ":RoleCodeType"}/>
        <SimpleScrubEntry label="Resource assignment code type" path={path + ":ResourceAssignmentCodeType"}/>
        <SimpleScrubEntry label="Global calendar" path={path + ":Calendar"} showRolldownOption={true}/>
        <SimpleScrubEntry label="UDF type" path={path + ":UDFType"}/>
        <SimpleScrubEntry label="Role" path={path + ":Role"}/>
        <SimpleScrubEntry label="Resource" path={path + ":Resource"}/>
        <SimpleScrubEntry label="Resource shift" path={path + ":Shift"}/>
        <SimpleScrubEntry label="Resource curve" path={path + ":ResourceCurve"}/>
      </Grid>
    </>
  );
};

export default SimpleScrub;
