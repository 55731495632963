//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import * as Utils from "eai-configurator-ui/components/Utils";
import Tooltip from "@material-ui/core/Tooltip";
import ManageUsersDialog from "src/components/scrubber/dialog/ManageUsersDialog";
import PeopleIcon from "@material-ui/icons/People";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 300,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
}));

export default function ManageCompaniesTable({
                                               setAlertMessage,
                                               refreshCounter,
                                               handleEditCompany,
                                               handleEditUsers,
                                               isFileProfile,
                                               isLiveProfile
                                             }) {
  const classes = useStyles();
  const [companies, setCompanies] = React.useState([]);
  const [manageUsersDialogOpen, setManageUsersDialogOpen] = React.useState(
    false
  );

  React.useEffect(() => {
    populateTable();
  }, [refreshCounter]);

  const populateTable = () => {
    Utils.fetchData("scrubber/companies")
      .then((data) => {
        setCompanies(data.companies);
      })
      .catch(function (error) {
        setAlertMessage({
          success: false,
          message:
            "Failed to fetch companies." +
            (error.statusText ? " " + error.statusText : ""),
        });
      });
  };

  const isTableEmpty = companies.length === 0;
  return (
    <div className={classes.root}>
      <ManageUsersDialog
        open={manageUsersDialogOpen}
        isFileProfile={isFileProfile}
        isLiveProfile={isLiveProfile}
        handleClose={() => setManageUsersDialogOpen(false)}
      />
      <Paper className={classes.paper}>
        <Toolbar className={clsx(classes.toolbar)}>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Companies
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="Companies">
            {!isTableEmpty && (
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="center">Subscription Username</TableCell>
                  <TableCell align="center">Subscription Password</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {companies.map((company, index) => (
                <TableRow key={index} hover role="task" tabIndex={-1}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {company.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {company.subscriptionUsername}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    {company.subscriptionPassword.replace(/./g, "*")}
                  </TableCell>
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    padding="default"
                  >
                    <Tooltip title="Edit Company">
                      <IconButton
                        onClick={() => {
                          handleEditCompany(company);
                        }}
                      >
                        <EditIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Users">
                      <IconButton
                        onClick={() => {
                          handleEditUsers(company);
                        }}
                      >
                        <PeopleIcon/>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow style={{height: 53}}>
                  <TableCell>
                    <Typography align="center" color="textSecondary">
                      No companies
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
