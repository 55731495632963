//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import Divider from "@material-ui/core/Divider";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationResourceCalendarTable from "src/components/scrubber/calendar/table/ConfigurationResourceCalendarTable";

const ResourceCalendarPage = ({ path, isLiveScrubberProfile }) => {
  return (
    <React.Fragment>
      <Label title="Resource Calendar Remapping" />
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <Divider light style={{ marginTop: 8 }} />
      <p />
      <ConfigurationResourceCalendarTable
        path={path + ":resourceCalendars"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default ResourceCalendarPage;
