//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiPhoneNumber from "material-ui-phone-number";
import Pricing from "src/components/scrubber/dialog/Pricing";
import * as Utils from "eai-configurator-ui/components/Utils";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
}));

const tiers = [
  {
    title: "Standalone",
    price: "100",
    description: ["5 projects"],
  },
  {
    title: "Standard",
    price: "50",
    description: ["100 projects"],
  },
  {
    title: "Pro",
    price: "25",
    description: ["250 projects"],
  },
  {
    title: "Corporate",
    price: "15",
    description: ["500 projects"],
  },
];
export default function SignUpDialog({ signupButton }) {
  const [submissionErrorOccurred, setSubmissionErrorOccurred] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [selectedTierTitle, setSelectedTierTitle] = React.useState(
    tiers[2].title
  );

  const classes = useStyles();
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleChangePhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setResultMessage(null);

    const details = {
      name: name,
      email: email,
      company: company,
      country: country,
      phoneNumber: phoneNumber,
      selectedTier: selectedTierTitle,
    };
    let formBody = [];
    for (const property in details) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    Utils.fetchData("scrubber/signUpUser", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    })
      .then((data) => {
        if (data.success) {
          setResultMessage(
            "Thank you for your request. You should receive an email with further instructions within a few hours."
          );
          setSubmissionErrorOccurred(false);
        } else {
          setResultMessage(
            "Request failed. Please contact Emerald support for help." +
              (data.message ? " Error details: " + data.message : "")
          );
          setSubmissionErrorOccurred(true);
          setSubmitted(false);
        }
      })
      .catch((e) => {
        setResultMessage(
          "Request failed. Please contact Emerald support for help. " +
            (e.statusText ? e.statusText : "")
        );
        setSubmissionErrorOccurred(true);
        setSubmitted(false);
      });
  };
  return (
    <div className={classes.paper}>
      <ValidatorForm onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Pricing
          tiers={tiers}
          selectedTierTitle={selectedTierTitle}
          handleSelectedTier={setSelectedTierTitle}
        />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextValidator
              variant="outlined"
              fullWidth
              label="Name"
              onChange={handleChangeName}
              name="name"
              value={name}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextValidator
              variant="outlined"
              fullWidth
              label="Email"
              onChange={handleChangeEmail}
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={["This field is required", "Email is not valid"]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextValidator
              variant="outlined"
              fullWidth
              label="Company"
              onChange={handleChangeCompany}
              name="company"
              value={company}
              validators={["required"]}
              errorMessages={["This field is required", "Company is not valid"]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextValidator
              variant="outlined"
              fullWidth
              label="Country"
              onChange={handleChangeCountry}
              name="country"
              value={country}
              validators={["required"]}
              errorMessages={["This field is required", "Country is not valid"]}
            />
          </Grid>
        </Grid>
        <MuiPhoneNumber
          variant="outlined"
          fullWidth
          name="phone"
          label="Phone Number"
          data-cy="user-phone"
          defaultCountry={"us"}
          value={phoneNumber}
          onChange={handleChangePhoneNumber}
        />
        <Typography
          className={
            submitted && !submissionErrorOccurred
              ? classes.success
              : classes.error
          }
        >
          {resultMessage}
        </Typography>
        <br />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={submitted}
        >
          {(submitted && (resultMessage ? "Submitted" : "Please wait...")) ||
            (!submitted && "Submit")}
        </Button>
      </ValidatorForm>
    </div>
  );
}
