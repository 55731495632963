//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationAsyncComboBox from "eai-configurator-ui/components/configuration/base/ConfigurationAsyncComboBox";

const GeneralSettings = ({ path }) => {
  return (
    <React.Fragment>
      <Label title="General Settings"/>
      <ConfigurationSwitch path={path + ":includeNotebookInFile"}/>
      <p/>
      <ConfigurationSwitch path={path + ":includeIndicatorsInFile"}/>
      <p/>
      <ConfigurationSwitch path={path + ":overrideVersionOnSave"}/>
      <p/>
      <ConfigurationAsyncComboBox
        path={path + ":versionOnSave"}
        url={"scrubber/metadataVersions"}
        autoSelect={false}
        transformHttpResponse={(data) => {
          return {
            options: data.entries.map((entry) => entry.versionNumber),
          };
        }}
      />
    </React.Fragment>
  );
};
export default GeneralSettings;
