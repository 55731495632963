//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationResourceTable from "src/components/scrubber/resource/ConfigurationResourceTable";
import InfoBox from "eai-configurator-ui/components/alert/InfoBox";
import ParentResourceSelection from "src/components/scrubber/resource/ParentResourceSelection";

const Resource = ({ path, isLiveScrubberProfile }) => {
  const [infoOpen, setInfoOpen] = React.useState(true);
  return (
    <React.Fragment>
      <Label title="Resource Remapping" />
      {infoOpen && (
        <InfoBox title={"Info"} handleClose={() => setInfoOpen(false)}>
          <li>Rates will be set to override to hold the costs</li>
          <li>
            Resource Dependent Tasks - calendars will be replaced, adjustments
            to task durations may occur
          </li>
        </InfoBox>
      )}
      <p/>
      <ConfigurationSwitch path={path + ":enabled"}/>
      <p/>
      <ParentResourceSelection/>
      <p/>
      <ConfigurationResourceTable
        path={path + ":resources"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default Resource;
