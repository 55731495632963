//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const PatternAnyLetterCustomizationDialog = ({ open, handleApplyChanges }) => {
  const [count, setCount] = useState(1);
  const [caseSenstivity, setCaseSenstivity] = useState("ignore");

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="pattern-dialog">Any Letter (A-Z)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Summary: The pattern will match{" "}
          <b>{count <= 0 ? "unlimited number of" : count}</b> letter
          {count === 1 ? "" : "s"} ranging from{" "}
          <b>{caseSenstivity === "lower" ? "a-z" : "A-Z"}</b>
        </DialogContentText>
        <p />
        <TextField
          size="medium"
          label={"How many letters?"}
          type="number"
          variant="outlined"
          value={count}
          onChange={(event) => setCount(Math.max(0, event.target.value))}
          id="count"
          helperText={"Set to zero for unlimited"}
        />
        <p />
        <FormControl component="fieldset">
          <FormLabel component="legend">Case Sensitivity</FormLabel>
          <RadioGroup
            aria-label="case"
            name="case"
            value={caseSenstivity}
            onChange={(event) => setCaseSenstivity(event.target.value)}
          >
            <FormControlLabel
              label="Ignore Case"
              control={<Radio color="primary" />}
              value="ignore"
            />
            <FormControlLabel
              label="UPPER CASE"
              control={<Radio color="primary" />}
              value="upper"
            />
            <FormControlLabel
              label="lower case"
              control={<Radio color="primary" />}
              value="lower"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleApplyChanges(count, caseSenstivity)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PatternAnyLetterCustomizationDialog;
