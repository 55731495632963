//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as Utils from "eai-configurator-ui/components/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  error: {
    color: "red",
  },
  checkbox: {
    paddingLeft: 0,
  },
}));

const CompanyDialog = ({
  handleSuccess,
  handleClose,
  company: inputCompany,
}) => {
  const classes = useStyles();
  const [company, setCompany] = useState({ ...inputCompany });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const setCompanyName = (event) => {
    const companyClone = { ...company };
    companyClone.name = event.target.value;
    setCompany(companyClone);
  };
  const setSubscriptionUsername = (event) => {
    const companyClone = { ...company };
    companyClone.subscriptionUsername = event.target.value;
    setCompany(companyClone);
  };
  const setSubscriptionPassword = (event) => {
    const companyClone = { ...company };
    companyClone.subscriptionPassword = event.target.value;
    setCompany(companyClone);
  };
  const setEulaApproved = (event) => {
    const companyClone = { ...company };
    companyClone.eulaApproved = event.target.checked;
    setCompany(companyClone);
  };

  const isEditingExistingCompany = () => {
    return inputCompany;
  };

  const handleApply = () => {
    setSubmitted(true);
    const params = {
      name: company.name,
      subscriptionUsername: company.subscriptionUsername,
      subscriptionPassword: company.subscriptionPassword,
      eulaApproved: company.eulaApproved,
    };
    if (inputCompany) {
      params["id"] = inputCompany.id;
    }
    Utils.fetchData(
      "scrubber/company",
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: Utils.createFormBody(params),
      },
      false,
      false
    )
      .catch((e) => {
        setErrorMessage("Company edit failed");
        setSubmitted(false);
      })
      .then((response) => {
        if (response && response.ok) {
          handleClose();
          setSubmitted(false);
          handleSuccess(company);
        } else {
          setErrorMessage("Company edit failed");
          setSubmitted(false);
        }
      });
  };

  const isEditing = isEditingExistingCompany();
  return (
    <div>
      <CloseableDialog
        title={isEditing ? "Edit Company" : "Create New Company"}
        open
        maxWidth="sm"
        fullWidth
        handleClose={handleClose}
      >
        <ValidatorForm onSubmit={handleApply} style={{ width: "100%" }}>
          <DialogContent dividers>
            <TextValidator
              className={classes.textField}
              label="Company Name"
              type="text"
              variant="standard"
              value={company.name}
              onChange={setCompanyName}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
            <TextValidator
              className={classes.textField}
              label="License Subscription Username"
              type="text"
              variant="standard"
              value={company.subscriptionUsername}
              onChange={setSubscriptionUsername}
              id="license-username"
              autoComplete="username"
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
            <TextValidator
              className={classes.textField}
              label="License Subscription Password"
              type="password"
              variant="standard"
              autoComplete="new-password"
              value={company.subscriptionPassword}
              onChange={setSubscriptionPassword}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
            <Tooltip title="Company superusers can manage other users">
              <FormControlLabel
                value="superuser"
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={company.eulaApproved}
                    onChange={setEulaApproved}
                    color="primary"
                  />
                }
                label="EULA Approved"
                labelPlacement="end"
              />
            </Tooltip>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" disabled={submitted}>
              {submitted ? "Applying..." : "Apply"}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </CloseableDialog>
    </div>
  );
};
export default CompanyDialog;
