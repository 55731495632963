//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  getOptionFromPattern,
  PatternInputField,
  PatternInputFieldOptions,
} from "./PatternInputField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as Utils from "eai-configurator-ui/components/Utils";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  longTextField: {
    width: "100%",
  },
}));

const ValidationTextField = withStyles({
  root: {
    marginLeft: "30px",
    "& input:valid + fieldset": {
      borderColor: "#59E83D",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const PatternDefinitionPanel = ({ path, label }) => {
  const config = Config.fromPath(path, true);
  if (!config) {
    throw new Error("Config not found for " + path);
  }
  const patternFromConfig = config.data.value;
  const patternOption = getOptionFromPattern(patternFromConfig);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [pattern, setPattern] = useState(patternFromConfig);
  const [option, setOption] = useState(patternOption);
  const [patternsValid, setPatternsValid] = useState(true);
  /**
   * Stores array of patterns which were last validated (by issuing a REST call to the backend).
   */
  const [lastValidatedPattern, setLastValidatedPattern] = useState(null);

  const [testTextToValidate, setTestTextToValidate] = useState("");
  const [
    patternValidationFetchPending,
    setPatternValidationFetchPending,
  ] = useState(null);

  useEffect(() => {
    setLastValidatedPattern(null);
  }, [testTextToValidate]);
  useEffect(() => {
    if (
      !patternValidationFetchPending &&
      pattern &&
      lastValidatedPattern !== pattern
    ) {
      if (!testTextToValidate || !pattern) {
        setPatternsValid(null);
        return;
      }
      setPatternValidationFetchPending(true);
      setLastValidatedPattern(pattern);
      Utils.fetchData("scrubber/validatePatterns", {
        method: "post",
        body: JSON.stringify({
          text: testTextToValidate,
          patterns: [pattern],
        }),
      })
        .then((data) => {
          setPatternValidationFetchPending(false);
          setPatternsValid(data.pass);
        })
        .catch(function (error) {
          setPatternValidationFetchPending(false);
          setPatternsValid(null);
        });
    }
  }, [
    pattern,
    patternValidationFetchPending,
    testTextToValidate,
    lastValidatedPattern,
  ]);

  const handlePatternChange = (pattern) => {
    Config.setDataValue(path, pattern);
    setPattern(pattern);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSelectOption = (newOption, event) => {
    if (option !== newOption) {
      handlePatternChange(null);
    }
    setOption(newOption);
    handleClose(event);
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" mr={6}>
        <Box p={1}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleToggle}
            ref={anchorRef}
          >
            <EditIcon className={classes.extendedIcon} />
            {label}
          </Button>{" "}
        </Box>
        <Box width="100%">
          <PatternInputField
            option={option}
            handlePatternChange={handlePatternChange}
            pattern={pattern}
          />
        </Box>
        <Box flexShrink={0}>
          {option && (
            <ValidationTextField
              id="test-pattern"
              helperText="Provide text to test the pattern" // The pattern matches provided text!
              label="Test your pattern"
              className={classes.longTextField}
              variant="outlined"
              error={!patternsValid}
              value={testTextToValidate}
              onChange={(event) => setTestTextToValidate(event.target.value)}
            />
          )}
        </Box>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        // zIndex must be higher than 1300 (material ui Dialog default zIndex)
        style={{ zIndex: 1400 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem
                    onClick={(event) =>
                      handleSelectOption(
                        PatternInputFieldOptions.contains,
                        event
                      )
                    }
                  >
                    Contains
                  </MenuItem>
                  <MenuItem
                    onClick={(event) =>
                      handleSelectOption(
                        PatternInputFieldOptions.startsWith,
                        event
                      )
                    }
                  >
                    Starts With
                  </MenuItem>
                  <MenuItem
                    onClick={(event) =>
                      handleSelectOption(
                        PatternInputFieldOptions.endsWith,
                        event
                      )
                    }
                  >
                    Ends With
                  </MenuItem>
                  <MenuItem
                    onClick={(event) =>
                      handleSelectOption(
                        PatternInputFieldOptions.simplePattern,
                        event
                      )
                    }
                  >
                    Simple Pattern
                  </MenuItem>
                  <MenuItem
                    onClick={(event) =>
                      handleSelectOption(
                        PatternInputFieldOptions.advancedExpressionPattern,
                        event
                      )
                    }
                  >
                    Advanced Expression Pattern
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => handleSelectOption(null, event)}
                  >
                    None
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
export default PatternDefinitionPanel;
