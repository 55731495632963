//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import NewIcon from "@material-ui/icons/FiberNew";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import LightBulbIcon from "@material-ui/icons/EmojiObjects";
import StarIcon from "@material-ui/icons/Star";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const MatchP6Status = ({ value, onUpdate, ...props }) => {
  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={String(value) === "NoMatchAcknowledged"}
              onChange={(event) => {
                const acknowledged = event.target.checked;
                onUpdate(acknowledged ? "NoMatchAcknowledged" : "NoMatch");
              }}
              autoFocus
              onBlur={() => onUpdate(value)}
            />
          }
          label="OK"
        />
      </FormGroup>
      <FormHelperText>Acknowledge</FormHelperText>
    </FormControl>
  );
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "black",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    margin: 0,
    padding: 0,
    maxWidth: "100%",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return (
    <Tooltip
      placement="top-start"
      arrow
      classes={classes}
      {...props}
      title={
        <Alert severity={props.severity} variant="outlined">
          <AlertTitle>{props.title}</AlertTitle>
          {props.message}
        </Alert>
      }
    />
  );
}

export const createStatusEditorColumn = (
  path,
  hoverRowIndex,
  handleDeleteItem
) => {
  const config = Config.getDefault(path);

  const formater = (cell, row, rowIndex, { hoverIdx }) => {
    if (hoverIdx === rowIndex) {
      return (
        <BootstrapTooltip
          title={"Warning"}
          severity={"warning"}
          open={Boolean(row.statusErrorMessage)}
          message={row.statusErrorMessage}
        >
          <IconButton onClick={() => handleDeleteItem(row, rowIndex)}>
            <DeleteIcon />
          </IconButton>
        </BootstrapTooltip>
      );
    }
    if (row.status === "New") {
      return (
        <IconButton>
          <NewIcon style={{ color: "red" }} />
        </IconButton>
      );
    } else if (row.status === "Error") {
      return (
        <IconButton>
          <WarningIcon style={{ color: "orange" }} />
        </IconButton>
      );
    }
    return <div style={{ width: "20px", height: "20px" }} />;
  };
  return {
    dataField: config.key,
    text: "",
    sort: true,
    editable: false,
    formatter: formater,
    formatExtraData: { hoverIdx: hoverRowIndex },
    headerStyle: { width: "50px" },
    style: { height: "30px", padding: 0, textAlign: "center" },
  };
};

export const createMatchP6EditorColumn = (
  path,
  hoverRowIndex,
  handleDeleteItem
) => {
  function formatter(cell, row, rowIndex) {
    switch (cell) {
      case "Unknown":
        return null;
      case "Exact":
        return <CheckIcon style={{ color: "green" }} />;
      case "Suggestion":
        return <LightBulbIcon style={{ color: "orange" }} />;
      case "NoMatch":
        return <CloseIcon style={{ color: "red" }} />;
      case "NoMatchAcknowledged":
        return (
          <BootstrapTooltip
            title={"Info"}
            severity={"info"}
            message={
              "P6 Scrubber will allow creation in P6."
            }
          >
            <StarIcon from style={{ color: "blue" }} />
          </BootstrapTooltip>
        );
    }
    return null;
  }

  const config = Config.getDefault(path);
  return {
    dataField: config.key,
    text: config.data.label,
    sort: true,
    bulkEditable: true,
    headerStyle: { width: "120px" },
    formatter: formatter,
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex
    ) => {
      if (value === "Unknown") {
        return null;
      } else if (value === "Exact") {
        return formatter(value, row, rowIndex);
      }
      return <MatchP6Status {...editorProps} value={value} />;
    },
  };
};
