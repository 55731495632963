//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import clsx from "clsx";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from "eai-configurator-ui/components/Utils";
import Button from "@material-ui/core/Button";
import DownloadIcon from "@material-ui/icons/GetApp";
import DetailsIcon from "@material-ui/icons/Subject";
import DocumentIcon from "@material-ui/icons/Assignment";
import ErrorIcon from "@material-ui/icons/Error";
import {saveAs} from "file-saver";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TaskDetailsDialog from "./TaskDetailsDialog";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Scrubbed Files
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  addItem: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 500,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ScrubbedFilesTable({
  lastFileUploadTimestamp,
  setAlertMessage,
                                             alwaysDisplay
}) {
  const classes = useStyles();
  const [scheduledTimeoutId, setScheduledTimeoutId] = React.useState(null);
  const [tasks, setTasks] = React.useState([]);
  const [displayDetailsForTask, setDisplayDetailsForTask] = React.useState(
    null
  );
  const [
    lastTableRefreshTimestamp,
    setLastTableRefreshTimestamp,
  ] = React.useState(null);
  const componentIsMounted = React.useRef(true);

  React.useEffect(() => {
    if (lastTableRefreshTimestamp < lastFileUploadTimestamp) {
      populateTable(false);
    }
  }, [lastFileUploadTimestamp, lastTableRefreshTimestamp]);

  React.useEffect(() => {
    populateTable(true);
    return () => {
      componentIsMounted.current = false;
      if (scheduledTimeoutId) {
        clearTimeout(scheduledTimeoutId);
      }
    };
  }, []);

  const populateTable = (shouldRescheduleTimeout) => {
    setLastTableRefreshTimestamp(new Date().getTime());
    Utils.fetchData("scrubber/tasks")
      .then((data) => {
        setTasks(data.tasks);
        if (shouldRescheduleTimeout) {
          rescheduleTimeout();
        }
      })
      .catch(function (error) {
        setAlertMessage({
          success: false,
          message:
            "Failed to refresh scrubbed files." +
            (error.statusText ? " " + error.statusText : ""),
        });
        if (shouldRescheduleTimeout) {
          rescheduleTimeout();
        }
      });
  };

  const rescheduleTimeout = () => {
    if (scheduledTimeoutId) {
      clearTimeout(scheduledTimeoutId);
    }
    if (componentIsMounted.current) {
      setScheduledTimeoutId(setTimeout(() => populateTable(true), 3000));
    }
  };

  const extractFileName = (contentDispositionValue) => {
    let filename = "";
    if (
      contentDispositionValue &&
      contentDispositionValue.indexOf("attachment") !== -1
    ) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  };
  const handleDisplayResults = (task) => {
    if (task.isSuccess) {
      const options = {
        headers: {
          "Cache-Control": "public, max-age=80000, immutable",
        },
      };
      fetch(
        Utils.getBaseUrl() + "scrubber/download?id=" + task.id,
        options
      ).then(async (response) => {
        const filename = extractFileName(
          response.headers.get("Content-Disposition")
        );
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    } else {
      setAlertMessage(task);
    }
  };
  const handleDisplayReport = (task) => {
    if (task.isSuccess) {
      const options = {
        headers: {
          "Cache-Control": "public, max-age=80000, immutable",
        },
      };
      fetch(Utils.getBaseUrl() + "scrubber/report?id=" + task.id, options)
        .then((response) => response.blob())
        .then((response) => {
          const file = new Blob([response], { type: "text/html" });
          const fileURL = URL.createObjectURL(file);
          const newWindow = window.open(fileURL);
          setTimeout(() => (newWindow.document.title = task.name));
        });
    } else {
      // TODO
      setAlertMessage(task);
    }
  };
  const removeResults = (task) => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(updatedTasks.indexOf(task), 1);
    setTasks(updatedTasks);

    Utils.fetchData("scrubber/remove?id=" + task.id);
  };

  const isTableEmpty = tasks.length === 0;
  if (alwaysDisplay === true) {
    console.log("Always showing the table per configuration")
  } else {
    if (isTableEmpty) {
      return null;
    }
  }
  return (
    <div className={classes.root}>
      {displayDetailsForTask && (
        <TaskDetailsDialog
          taskId={displayDetailsForTask}
          handleClose={() => setDisplayDetailsForTask(null)}
        />
      )}
      {
        <Paper className={classes.paper}>
          <EnhancedTableToolbar />

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>File name</TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Scrubber Definition</TableCell>
                  <TableCell align="center">Scrubbed At</TableCell>
                  <TableCell align="center">Result</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task) => (
                  <TableRow hover role="task" tabIndex={-1}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {task.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {task.username}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {task.profileName}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {new Date(task.startTimestamp).toLocaleString()}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {task.isCompleted ? (
                        <div>
                          {task.isSuccess && (
                            <Button
                              variant="contained"
                              color={task.isSuccess ? "primary" : "secondary"}
                              className={classes.button}
                              onClick={() => setDisplayDetailsForTask(task.id)}
                              startIcon={<DetailsIcon />}
                            >
                              Details
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color={task.isSuccess ? "primary" : "secondary"}
                            className={classes.button}
                            onClick={() => handleDisplayResults(task)}
                            startIcon={
                              task.isSuccess ? <DownloadIcon /> : <ErrorIcon />
                            }
                          >
                            {task.isSuccess ? "Download Results" : "View Error"}
                          </Button>
                          {task.isSuccess && (
                            <Button
                              variant="contained"
                              color={task.isSuccess ? "primary" : "secondary"}
                              className={classes.button}
                              onClick={() => handleDisplayReport(task)}
                              startIcon={<DocumentIcon />}
                            >
                              View Report
                            </Button>
                          )}
                        </div>
                      ) : (
                        <CircularProgress />
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      <IconButton onClick={() => removeResults(task)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {isTableEmpty && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell>
                      <Typography align="center" color="textSecondary">
                        Empty
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      }
    </div>
  );
}
