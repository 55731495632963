//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimplePatternInputField, {
  isPatternSimple,
} from "./simple/SimplePatternInputField";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  longTextField: {
    width: "100%",
  },
}));

const patternQuoteStart = "\\Q";
const patternQuoteEnd = "\\E";
const dotStar = ".*";
const quotePattern = (pattern) => {
  return patternQuoteStart + pattern + patternQuoteEnd;
};

const getRegexSplitQuotes = (pattern) => {
  const regexp = new RegExp("\\\\Q|\\\\E");
  return pattern.split(regexp);
};

const options = {
  contains: {
    id: "contains",
    name: "Contains",
    isPatternValid: function (pattern) {
      return (
        pattern &&
        getRegexSplitQuotes(pattern).length === 3 &&
        pattern.startsWith(dotStar + patternQuoteStart) &&
        pattern.endsWith(patternQuoteEnd + dotStar)
      );
    },
    getDisplayValueFromPattern: function (pattern) {
      if (this.isPatternValid(pattern)) {
        return pattern.substring(
          patternQuoteStart.length + dotStar.length,
          pattern.length - (patternQuoteEnd.length + dotStar.length)
        );
      }
      return "";
    },
    getPatternFromDisplayValue: function (displayValue) {
      if (!displayValue) {
        return displayValue;
      }
      return `${dotStar}${quotePattern(displayValue)}${dotStar}`;
    },
  },
  startsWith: {
    id: "startsWith",
    name: "Starts With",
    isPatternValid: function (pattern) {
      return (
        pattern &&
        getRegexSplitQuotes(pattern).length === 3 &&
        pattern.startsWith(patternQuoteStart) &&
        pattern.endsWith(patternQuoteEnd + dotStar)
      );
    },
    getDisplayValueFromPattern: function (pattern) {
      if (this.isPatternValid(pattern)) {
        return pattern.substring(
          patternQuoteStart.length,
          pattern.length - (patternQuoteEnd.length + dotStar.length)
        );
      }
      return "";
    },
    getPatternFromDisplayValue: function (displayValue) {
      if (!displayValue) {
        return displayValue;
      }
      return `${quotePattern(displayValue)}${dotStar}`;
    },
  },
  endsWith: {
    id: "endsWith",
    name: "Ends With",
    isPatternValid: function (pattern) {
      return (
        pattern &&
        getRegexSplitQuotes(pattern).length === 3 &&
        pattern.startsWith(dotStar + patternQuoteStart) &&
        pattern.endsWith(patternQuoteEnd)
      );
    },
    getDisplayValueFromPattern: function (pattern) {
      if (this.isPatternValid(pattern)) {
        return pattern.substring(
          patternQuoteStart.length + dotStar.length,
          pattern.length - patternQuoteEnd.length
        );
      }
      return "";
    },
    getPatternFromDisplayValue: function (displayValue) {
      if (!displayValue) {
        return displayValue;
      }
      return `${dotStar}${quotePattern(displayValue)}`;
    },
  },
  simplePattern: {
    id: "customSimple",
    name: "Simple Pattern",
    isPatternValid: function (pattern) {
      return isPatternSimple(pattern);
    },
    getDisplayValueFromPattern: function (pattern) {
      return pattern;
    },
    getPatternFromDisplayValue: function (displayValue) {
      return displayValue;
    },
  },
  advancedExpressionPattern: {
    id: "advanced",
    name: "Advanced Expression",
    isPatternValid: function (pattern) {
      return true;
    },
    getDisplayValueFromPattern: function (pattern) {
      return pattern;
    },
    getPatternFromDisplayValue: function (displayValue) {
      return displayValue;
    },
  },
};

const getOptionFromPattern = (pattern, doNotUseSimplePattern) => {
  if (!pattern) {
    return null;
  }
  return [
    options.contains,
    options.startsWith,
    options.endsWith,
    options.simplePattern,
    options.advancedExpressionPattern,
  ]
    .filter(
      (option) => !doNotUseSimplePattern || option !== options.simplePattern
    )
    .find((option) => option.isPatternValid(pattern));
};

const PatternInputField = ({
  handlePatternChange,
  option,
  pattern,
  isInsideGrid,
}) => {
  const classes = useStyles();

  const inputValue =
    option && option !== options.simplePattern
      ? option.getDisplayValueFromPattern(pattern)
      : null;
  const simplePatternValue = option === options.simplePattern ? pattern : null;

  const handleTextFieldValueChange = (event) => {
    if (option) {
      handlePatternChange(
        option.getPatternFromDisplayValue(event.target.value)
      );
    }
  };
  const handleSimplePatternValueChange = (pattern) => {
    if (option === options.simplePattern) {
      handlePatternChange(pattern);
    }
  };

  const handleShowAdvancedExpressionHelp = () => {
    window.open("https://en.wikipedia.org/wiki/Regular_expression", "_blank");
  };

  const useSimplePattern = option === options.simplePattern;
  return (
    <React.Fragment>
      {option && useSimplePattern && (
        <SimplePatternInputField
          handlePatternChange={handleSimplePatternValueChange}
          pattern={simplePatternValue}
          isInsideGrid={isInsideGrid}
        />
      )}
      {option && !useSimplePattern && (
        <TextField
          size="medium"
          label={option.name}
          type="text"
          className={classes.longTextField}
          variant={isInsideGrid ? "standard" : "outlined"}
          value={inputValue || ""}
          onChange={handleTextFieldValueChange}
          id={option.name}
          InputProps={
            (option === options.advancedExpressionPattern && {
              startAdornment: (
                <InputAdornment position="start">
                  <HelpIcon onClick={handleShowAdvancedExpressionHelp} />
                </InputAdornment>
              ),
            }) ||
            {}
          }
        />
      )}
    </React.Fragment>
  );
};
export {
  getOptionFromPattern,
  options as PatternInputFieldOptions,
  PatternInputField,
};
