//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { createTextEditorColumn } from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const ConfigurationProjectCalendarTable = ({ path, isLiveScrubberProfile }) => {
  const [searchVisible, setSearchVisible] = React.useState(false);
  const columns = [
    createTextEditorColumn(path + ":calendar:name", searchVisible),
    createTextEditorColumn(path + ":calendar:projectId", searchVisible),
  ];
  return (
    <ScrubberConfigurationTable
      path={path}
      childKey={"calendar"}
      columns={columns}
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      hideAcceptAllOption={true}
      allowBulkEdit
      disableMatchP6Column
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};

export default ConfigurationProjectCalendarTable;
