//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, {useEffect, useState} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import * as Utils from "eai-configurator-ui/components/Utils";
import {HtmlString} from "eai-configurator-ui/components/Utils";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";

const TaskDetailsDialog = ({taskId, handleClose}) => {
    const [details, setDetails] = useState(null);
    useEffect(() => {
        fetchDetails();
    }, [taskId]);

    const fetchDetails = () => {
        if (taskId) {
            Utils.fetchData("scrubber/taskDetails?id=" + taskId)
                .then((data) => {
                    setDetails(data.details);
                })
                .catch(function (error) {
                });
        }
    };

    const handleCopyToClipboard = () => {
        Utils.copyFormattedHtmlToClipboard(details);
    };

    return (
        <CloseableDialog
            open
            maxWidth="sm"
            fullWidth
            handleClose={handleClose}
            title="Task Information"
        >
            <DialogContent dividers>
                <HtmlString text={details}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleCopyToClipboard}>
                    Copy to clipboard
                </Button>
            </DialogActions>
        </CloseableDialog>
    );
};
export default TaskDetailsDialog;
