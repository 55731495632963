//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "35%",
    flexShrink: 0,
  },
}));
const IndicatorInfoItem = ({ title, green, yellow, red, blank }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <span className={classes.heading}>{title}</span>
      <List dense component="nav" aria-label="info">
        {green && (
          <ListItem>
            <ListItemIcon>
              <Icon style={{ color: "#01ff01" }}>{"check_circle"}</Icon>
            </ListItemIcon>
            <ListItemText primary={green} />
          </ListItem>
        )}
        {red && (
          <ListItem>
            <ListItemIcon>
              <Icon style={{ color: "#e74100" }}>{"cancel"}</Icon>
            </ListItemIcon>
            <ListItemText primary={red} />
          </ListItem>
        )}
        {yellow && (
          <ListItem>
            <ListItemIcon>
              <Icon style={{ color: "#ffff01" }}>{"warning"}</Icon>
            </ListItemIcon>
            <ListItemText primary={yellow} />
          </ListItem>
        )}
        {blank && (
          <ListItem>
            <ListItemIcon/>
            <ListItemText primary={blank} />
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
};
export default IndicatorInfoItem;
