//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfigurationTable from "eai-configurator-ui/components/configuration/table/ConfigurationTable";
import {createMatchP6EditorColumn, createStatusEditorColumn,} from "src/components/scrubber/table/ScrubberConfigurationTableEditor";
import {createDataIndexEditorColumn} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import {useConfigProfile} from "eai-configurator-ui/components/configuration/profile/ConfigurationProfile";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import * as Utils from "eai-configurator-ui/components/Utils";

const ScrubberConfigurationTable = ({
                                      path,
                                      childKey,
                                      columns,
                                      validationConfigPath,
                                      disableMatchP6Column,
                                      isLiveScrubberProfile,
                                      hideAcceptAllOption = false,
                                      ...props
                                    }) => {

  //
  // Some properties need to come from the parent - the parent path is used in some toggles - we don't want the children list
  const parentPath = path.substring(0, path.lastIndexOf(":"))

  const [validationFetchPending, setValidationFetchPending] = React.useState(
    false
  );
  const [hoverRowIndex, setHoverRowIndex] = React.useState(null);
  const [hoverDataIndex, setHoverDataIndex] = React.useState(null);
  const [profile, setConfigProfile] = useConfigProfile();

  const acceptAllPath = () => {
    if (parentPath) return parentPath
    else return path
  }

  const handleCellEdited = (oldValue, newValue, row, column) => {
    if (validationFetchPending) {
      // Avoid querying validation if one is already pending
      return;
    }
    setValidationFetchPending(true);

    let editedPath = validationConfigPath
      ? validationConfigPath
      : `${path}:${childKey}[${row.dataIndex}]`;
    let editedConfigurationValue = Config.fromPath(editedPath);
    if (editedConfigurationValue) {
      let it = Config.deepClone(
        editedConfigurationValue,
        editedConfigurationValue.parent
      );

      // Clone the parents and clear all children - only keep the edited configuration
      while (it.parent) {
        const clonedParent = {...it.parent};
        clonedParent.children = [it];
        it = clonedParent;
      }

      // Validate configuration
      Utils.fetchData("configuration/validate", {
        method: "post",
        body: JSON.stringify({
          values: Config.buildConfigurationValueDtos([it]),
        }),
      })
        .then((data) => {
          const values = data.values;
          if (values && values.length === 1) {
            let value = values[0];
            Config.initializeConfigurationValueHierarchy(value);

            const pathArray = Config.pathToArray(editedPath);
            for (let i = 1; i < pathArray.length && value; i++) {
              let key = pathArray[i];
              if (isNaN(key)) {
                value = value.children.find((child) => child.key === key);
              }
            }

            if (value) {
              Config.updateChild(
                editedConfigurationValue.parent,
                editedConfigurationValue,
                value
              );
              setConfigProfile({
                doNotReloadConfigurationValues: true,
                ...profile,
              });
            }
          }
          setValidationFetchPending(false);
        })
        .catch(function (error) {
          setValidationFetchPending(false);
        });
    }
  };

  const handleDeleteItem = (row, rowIndex) => {
    if (row) {
      Config.deleteChild(path, row.dataIndex);
      setHoverRowIndex(null);
    }
  };

  const rowEvents = {
    onMouseEnter: (e, row, rowIndex) => {
      setHoverRowIndex(rowIndex);
      setHoverDataIndex(row.dataIndex);
    },
    onMouseLeave: () => {
      setHoverRowIndex(null);
      setHoverDataIndex(null);
    },
  };

  const createStatusErrorMessageColumn = () => {
    return {
      dataField: "statusErrorMessage",
      hidden: true,
      type: "number",
    };
  };

  const sliderAcceptAllValuesAction = (checkedValue) => {
    const checked = String(checkedValue) === "true"
    const config = Config.fromPath(path);
    let changes = 0
    if (config) {
      const values = config.children
      if (values && Array.isArray(values) && values.length > 0) {
        values.forEach((value) => {
          const properties = value.children
          if (properties && Array.isArray(properties)) {
            const matchesP6 = properties.find((item) => {
              return item.key === "matchesP6"
            })
            if (matchesP6 && matchesP6.hasOwnProperty("data")) {
              const currentEntry = matchesP6.data
              const currentValue = currentEntry.value
              if (checked && currentValue === "NoMatch") {
                currentEntry.value = "NoMatchAcknowledged"
                changes++
              } else if (!checked && currentValue === "NoMatchAcknowledged") {
                currentEntry.value = "NoMatch"
                changes++
              }
            }
          }
        });
      }
    }

    if (changes > 0) {
      Config.setData(path, config)
    }
  }

  const columnsWithStatusAndDataIndex = [
    createStatusEditorColumn(
      `${path}:${childKey}:status`,
      hoverRowIndex,
      handleDeleteItem
    ),
    createStatusErrorMessageColumn(),
    createDataIndexEditorColumn(),
    ...columns,
  ];
  if (!disableMatchP6Column && isLiveScrubberProfile) {
    columnsWithStatusAndDataIndex.push(
      createMatchP6EditorColumn(`${path}:${childKey}:matchesP6`)
    );
  }
  const title = Config.getDefault(path).data.label;
  let acceptAllValuesPath = null;
  if (!hideAcceptAllOption) {
    acceptAllValuesPath = acceptAllPath() + ":acceptAllValues"
  }

  return (
    <div style={{width: "100%"}}>
      <ConfigurationTable
        path={path}
        childKey={childKey}
        onHoverDataIndexChanged={setHoverDataIndex}
        rowEvents={rowEvents}
        columns={columnsWithStatusAndDataIndex}
        afterSaveCell={handleCellEdited}
        sliderConfigKeyPath={acceptAllValuesPath}
        sliderAction={sliderAcceptAllValuesAction}
        title={
          <span>
            {title}
            {validationFetchPending && (
              <CircularProgress
                color="inherit"
                style={{marginLeft: "10px"}}
                size={20}
              />
            )}
          </span>
        }
        {...props}
      />
    </div>
  );
};

export default ScrubberConfigurationTable;
