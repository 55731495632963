//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import PeopleIcon from "@material-ui/icons/People";
import IconButton from "@material-ui/core/IconButton";

const ManageUsersButton = ({useIconButton, handleClick}) => {
  if (useIconButton) {
    return (
      <IconButton onClick={handleClick}>
        <PeopleIcon/>
      </IconButton>
    );
  }
  return (
    <Button
      onClick={handleClick}
      startIcon={<PeopleIcon/>}
    >
      Manage Users
    </Button>
  );
};
export default ManageUsersButton;
