//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import BusinessIcon from "@material-ui/icons/Business";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ManageCompaniesTable from "src/components/scrubber/table/ManageCompaniesTable";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import CompanyEditDialog from "src/components/scrubber/dialog/CompanyEditDialog";
import UserEditDialog from "src/components/scrubber/dialog/UserEditDialog";

const ManageCompaniesButton = ({ handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      startIcon={<BusinessIcon />}
      style={{ padding: "4px 10px" }}
    >
      Manage Companies
    </Button>
  );
};
export default ManageCompaniesButton;
