//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import ReactDOM from "react-dom";
import ScrubberApplication from "src/components/scrubber/ScrubberApplication";

function App() {
  return (
    <div>
        <ScrubberApplication />
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
