//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ManageUsersTable from "src/components/scrubber/table/ManageUsersTable";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import UserEditDialog from "src/components/scrubber/dialog/UserEditDialog";
import * as Utils from "eai-configurator-ui/components/Utils";
import Tooltip from "@material-ui/core/Tooltip";

const ManageUsersDialog = ({companyId, isFileProfile, isLiveProfile, useIconButton, open, handleClose}) => {
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [userPendingEdit, setUserPendingEdit] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [tableRefreshCounter, setTableRefreshCounter] = React.useState(0);

  const handleCloseEditedProfile = () => {
    setUserPendingEdit(false);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserPendingEdit(true);
  };

  const handleDeleteUser = (user) => {
    Utils.fetchData(
      "scrubber/user",
      {
        method: "delete",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: Utils.createFormBody({
          username: user.email,
        }),
      },
      false,
      false
    )
      .then((response) => {
        // Refresh the table which will issue a new fetch and update its contents
        setTableRefreshCounter(tableRefreshCounter + 1);
      })
      .catch(function (error) {
        setAlertMessage({
          success: false,
          message:
            "Failed to delete user." +
            (error.statusText ? " " + error.statusText : ""),
        });
      });
  };

  const handleSetUserEnabled = (email, enabled) => {
    Utils.fetchData(
      "scrubber/updateUserEnabled",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: Utils.createFormBody({
          username: email,
          enabled: enabled
        }),
      },
      false,
      false
    ).catch(function (error) {
      setAlertMessage({
        success: false,
        message:
          "Failed to update user." +
          (error.statusText ? " " + error.statusText : ""),
      });
    });
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setUserPendingEdit(true);
  };

  const handleApplyUserChanges = (user) => {
    // Refresh the table which will issue a new fetch and update its contents
    setTableRefreshCounter(tableRefreshCounter + 1);
  };

  return (
    <span>
      {userPendingEdit && (
        <UserEditDialog
          user={selectedUser}
          handleClose={handleCloseEditedProfile}
          handleSuccess={handleApplyUserChanges}
          isFileProfile={isFileProfile}
          isLiveProfile={isLiveProfile}
          companyId={companyId}
        />
      )}
      {alertMessage && (
        <Portal>
          <Snackbar
            open={Boolean(alertMessage)}
            autoHideDuration={6000}
            onClose={() => setAlertMessage(null)}
          >
            <Alert
              onClose={() => setAlertMessage(null)}
              severity={
                alertMessage && alertMessage.success ? "success" : "error"
              }
            >
              {alertMessage && (alertMessage.message || "Unknown error")}
            </Alert>
          </Snackbar>
        </Portal>
      )}
      <CloseableDialog
        open={open}
        title={"Manage Users"}
        maxWidth="lg"
        fullWidth
        handleClose={handleClose}
      >
        <DialogContent dividers>
          <ManageUsersTable
            refreshCounter={tableRefreshCounter}
            setAlertMessage={setAlertMessage}
            handleEditUser={handleEditUser}
            handleDeleteUser={handleDeleteUser}
            handleSetUserEnabled={handleSetUserEnabled}
            isFileProfile={isFileProfile}
            isLiveProfile={isLiveProfile}
            companyId={companyId}
          />
        </DialogContent>
        <DialogActions>
          {isFileProfile &&
            <Tooltip title="Manage Company Users">
              <Button color="primary" onClick={handleAddUser}>
                Add User
              </Button>
            </Tooltip>
          }
        </DialogActions>
      </CloseableDialog>
    </span>
  );
};
export default ManageUsersDialog;
