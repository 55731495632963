//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import Divider from "@material-ui/core/Divider";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import ConfigurationGlobalCalendarTable from "src/components/scrubber/calendar/table/ConfigurationGlobalCalendarTable";
import InfoBox from "eai-configurator-ui/components/alert/InfoBox";

const GlobalCalendarRolldown = ({ path, isLiveScrubberProfile }) => {
  const [infoOpen, setInfoOpen] = React.useState(true);
  return (
    <React.Fragment>
      <Label title="Calendar Rolldown" />
      {infoOpen && (
        <InfoBox title={"Info"} handleClose={() => setInfoOpen(false)}>
          <li>
            Global Calendars assigned to Resources used on Resource Dependent
            Tasks will not be rolled down.
          </li>
        </InfoBox>
      )}
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <Divider light style={{ marginTop: 8 }} />
      <ConfigurationCheckBox path={path + ":rolldownExtraCalendars"} />
      <p />
      <ConfigurationGlobalCalendarTable
        path={path + ":globalCalendars"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
      <Divider light style={{ margin: 15 }} />
    </React.Fragment>
  );
};
export default GlobalCalendarRolldown;
