//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import FilesIcon from "@material-ui/icons/FileCopy";
import DialogContent from "@material-ui/core/DialogContent";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import ScrubberUpload from "../ScrubberUpload";
import * as Utils from "eai-configurator-ui/components/Utils";
import * as Config from "eai-configurator-ui/components/configuration/utils/ConfigurationRegistry";
import ScrubbedFilesTable from "src/components/scrubber/table/ScrubbedFilesTable";
import ErrorMessageDialog from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";
import { useConfigProfile } from "eai-configurator-ui/components/configuration/profile/ConfigurationProfile";

const ScrubFilesButton = ({ onApply }) => {
  const [open, setOpen] = React.useState(false);
  const [requestPending, setRequestPending] = React.useState(false);
  const [lastFileUploadTimestamp, setLastFileUploadTimestamp] = useState(0);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [errorNotification, setErrorNotification] = React.useState(null);
  const [profile] = useConfigProfile();

  useEffect(() => {
    if (open) {
      if (Config.isEdited()) {
        setErrorNotification({
          open: true,
          title: "Unsaved changes",
          message:
            "Some configuration changes were made. Please save or load configuration definition to continue.",
        });
        setOpen(false);
      } else if (!profile || !profile.name) {
        setErrorNotification({
          open: true,
          title: "Definition not loaded",
          message: "Please load a definition first to scrub files.",
        });
        setOpen(false);
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const upload = (file, profile) => {
    if (!requestPending) {
      setRequestPending(true);
      const data = new FormData();
      data.append("file", file);
      data.append("profile", JSON.stringify(profile));
      Utils.fetchData(
        "scrubber/upload",
        {
          method: "POST",
          body: data,
        },
        false
      )
        .then((success) => {
          setRequestPending(false);
          setLastFileUploadTimestamp(new Date().getTime());
        })
        .catch((error) => {
          setRequestPending(false);
          setAlertMessage({
            success: false,
            message: error.statusText,
          });
        });
    }
  };

  const handleSubmitFiles = (files) => {
    // upload and submit files
    for (let i = 0; i < files.length; i++) {
      upload(files[i], profile);
    }
    return true;
  };

  const closeErrorNotification = () => {
    if (errorNotification) {
      const notificationClone = { ...errorNotification };
      notificationClone.open = false;
      setErrorNotification(notificationClone);
    }
  };

  return (
    <div>
      <ErrorMessageDialog
        open={errorNotification != null && errorNotification.open}
        handleClose={closeErrorNotification}
        title={errorNotification && errorNotification.title}
        description={errorNotification && errorNotification.message}
        maxWidth="sm"
        fullWidth
      />
      {alertMessage && (
        <Portal>
          <Snackbar
            open={alertMessage}
            autoHideDuration={6000}
            onClose={() => setAlertMessage(null)}
          >
            <Alert
              onClose={() => setAlertMessage(null)}
              severity={
                alertMessage && alertMessage.success ? "success" : "error"
              }
            >
              {alertMessage && (alertMessage.message || "Unknown error")}
            </Alert>
          </Snackbar>
        </Portal>
      )}
      <CloseableDialog
        open={open}
        title={"Scrub XER/ XML File(s)"}
        maxWidth="lg"
        fullWidth
        handleClose={handleClose}
      >
        <DialogContent dividers>
          <ScrubberUpload
            handleSubmit={handleSubmitFiles}
            submitButtonLabel={"Process"}
            isImportRequestPending={requestPending}
          />
          <ScrubbedFilesTable
            lastFileUploadTimestamp={lastFileUploadTimestamp}
            setAlertMessage={setAlertMessage}
          />
        </DialogContent>
      </CloseableDialog>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<FilesIcon />}
      >
        Scrub Files
      </Button>
    </div>
  );
};
export default ScrubFilesButton;
