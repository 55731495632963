//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createCheckBoxEditorColumn,
  createComboBoxEditorColumn,
  createTextEditorColumn,
  createTextTypeAheadEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const ConfigurationUnitsOfMeasureTable = ({ path, isLiveScrubberProfile }) => {
  const [searchVisible, setSearchVisible] = React.useState(false);
  const suggestionsUrl = isLiveScrubberProfile
    ? "scrubber/unitsOfMeasure"
    : null;
  const columns = [
    createTextEditorColumn(path + ":unitOfMeasure:abbreviation", searchVisible),
    createTextEditorColumn(path + ":unitOfMeasure:name", searchVisible),
    createComboBoxEditorColumn(path + ":unitOfMeasure:action", searchVisible),
    createTextTypeAheadEditorColumn(
      path + ":unitOfMeasure:newAbbreviation",
      searchVisible,
      suggestionsUrl,
      (options) => options.map((option) => option.abbreviation)
    ),
    createTextTypeAheadEditorColumn(
      path + ":unitOfMeasure:newName",
      searchVisible,
      suggestionsUrl,
      (options, row) =>
        options
          .filter((option) => option.abbreviation === row.newAbbreviation)
          .map((option) => option.name)
    ),
  ];
  return (
    <ScrubberConfigurationTable
      path={path}
      childKey={"unitOfMeasure"}
      columns={columns}
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      allowBulkEdit
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};

export default ConfigurationUnitsOfMeasureTable;
