//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PersonalIcon from "@material-ui/icons/Person";
import SharedIcon from "@material-ui/icons/People";
import GlobalIcon from "@material-ui/icons/Public";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AsyncComboBox from "eai-configurator-ui/components//base/AsyncComboBox";
import {useSessionInfo} from "eai-configurator-ui/components/SessionInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  textField: {
    width: "100%",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: "100%",
    minWidth: 200,
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
}));

const PERMISSION_PERSONAL = "Personal";
const PERMISSION_GLOBAL = "Global";
const PERMISSION_TEAM = "Team";

const PERMISSION_HELPER_TEXT = {
  Personal: "Only visible to the user who creates it",
  Global: "Visible to all users in the company",
  Team: "Users in the same team share definitions",
};

const ScrubberDefinitionPermission = ({permissions, setPermissions}) => {
  const classes = useStyles();
  const [permissionName, setPermissionName] = useState(
    permissions ? permissions.name : PERMISSION_PERSONAL
  );
  const [permissionTeams, setPermissionTeams] = useState(
    permissions ? permissions.value : null
  );
  const [sessionInfo] = useSessionInfo();

  React.useEffect(() => {
    setPermissions({
      name: permissionName,
      value: permissionTeams,
    });
  }, [permissionName, permissionTeams]);

  const handlePermissionNameChange = (event) => {
    const name = event.target.value;
    setPermissionName(name);
    if (name !== PERMISSION_TEAM) {
      setPermissionTeams(null);
    }
  };

  let teamsUrl = "scrubber/teams";
  return (
    <div>
      <p/>
      <span>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Permission Level
          </InputLabel>
          <Select
            labelId="permission-label"
            id="permission-select"
            label="Permission Layer"
            value={permissionName}
            onChange={handlePermissionNameChange}
          >
            <MenuItem value={PERMISSION_PERSONAL}>
              <div style={{display: "flex", alignItems: "center"}}>
                <PersonalIcon className={classes.menuIcon}/>
                <div>{PERMISSION_PERSONAL}</div>
              </div>
            </MenuItem>
            <MenuItem value={PERMISSION_TEAM}>
              <div style={{display: "flex", alignItems: "center"}}>
                <SharedIcon className={classes.menuIcon}/>
                <div>{PERMISSION_TEAM}</div>
              </div>
            </MenuItem>
              <MenuItem value={PERMISSION_GLOBAL}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <GlobalIcon className={classes.menuIcon}/>
                  <div>{PERMISSION_GLOBAL}</div>
                </div>
              </MenuItem>
          </Select>
          <FormHelperText>
            {PERMISSION_HELPER_TEXT[permissionName]}
          </FormHelperText>
        </FormControl>
        {permissionName === PERMISSION_TEAM && (
          <AsyncComboBox
            url={teamsUrl}
            textFieldProps={{
              label: "Teams",
              fullWidth: true,
            }}
            value={permissionTeams || []}
            onChange={(event, value) => setPermissionTeams(value)}
            multiple
            autoSelect={false}
          />
        )}
      </span>
    </div>
  );
};
export default ScrubberDefinitionPermission;
