//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import InfoBox from "eai-configurator-ui/components/alert/InfoBox";
import ParentObsSelection from "src/components/scrubber/obs/ParentObsSelection";

const OBS = ({path, isLiveScrubberProfile}) => {
  const [infoOpen, setInfoOpen] = React.useState(true);
  return (
    <React.Fragment>
      <Label title="OBS Remapping"/>
      {infoOpen && (
        <InfoBox title={"Info"} handleClose={() => setInfoOpen(false)}>
          <li>Parent OBS Override</li>
        </InfoBox>
      )}
      <p/>
      <ConfigurationSwitch path={path + ":enabled"}/>
      <p/>
      <ParentObsSelection/>
    </React.Fragment>
  );
};
export default OBS;
