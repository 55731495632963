//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationCostAccountTable from "src/components/scrubber/cost_account/ConfigurationCostAccountTable";
import ConfigurationCheckBox from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBox";
import Divider from "@material-ui/core/Divider";

const CostAccount = ({ path, isLiveScrubberProfile }) => {
  return (
    <React.Fragment>
      <Label title="Cost Account Validation" />
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <Divider light style={{ marginTop: 8 }} />
      <ConfigurationCheckBox path={path + ":removeExtraCostAccounts"} />
      <p />
      <ConfigurationCostAccountTable
        path={path + ":costAccounts"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default CostAccount;
