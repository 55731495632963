//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import {
  createCheckBoxEditorColumn,
  createComboBoxEditorColumn,
  createTextEditorColumn,
  createTextTypeAheadEditorColumn,
} from "eai-configurator-ui/components/configuration/table/ConfigurationTableEditor";
import ScrubberConfigurationTable from "src/components/scrubber/table/ScrubberConfigurationTable";

const ConfigurationDictionaryTable = ({
  codeTypePath,
  codeTypeRow,
  subjectArea,
  isLiveScrubberProfile,
}) => {
  const [searchVisible, setSearchVisible] = React.useState(false);
  const dictionaryPath = codeTypePath + ":dictionary";
  console.log("Is live profile: " + isLiveScrubberProfile + ", dictionary path: " + dictionaryPath);
  const url = isLiveScrubberProfile
    ? (row) => {
        let suggestionsUrl =
          `scrubber/codeValues?subjectArea=` + subjectArea.toUpperCase();
        if (codeTypeRow.newName) {
          suggestionsUrl +=
            "&codeTypeName=" + encodeURIComponent(codeTypeRow.newName);
        }
        if (codeTypeRow.scope) {
          suggestionsUrl += "&activityScope=" + codeTypeRow.scope;
        }
        if (codeTypeRow.projectId) {
          suggestionsUrl +=
            "&objectId=" + encodeURIComponent(codeTypeRow.projectId);
        }
        return suggestionsUrl;
      }
    : null;
  const columns = [
    createTextEditorColumn(dictionaryPath + ":value:name", searchVisible),
    createTextEditorColumn(
      dictionaryPath + ":value:description",
      searchVisible
    ),
    createComboBoxEditorColumn(dictionaryPath + ":value:action", searchVisible),
    createTextTypeAheadEditorColumn(
      dictionaryPath + ":value:newName",
      searchVisible,
      url,
      (options) => options.map((option) => option.path)
    ),
    createTextTypeAheadEditorColumn(
      dictionaryPath + ":value:newDescription",
      searchVisible,
      url,
      (options, row) =>
        options
          .filter((option) => option.path === row.newName)
          .map((option) => option.description)
    ),
  ];
  return (
    <ScrubberConfigurationTable
      path={dictionaryPath}
      childKey={"value"}
      columns={columns}
      handleToggleSearchVisibility={() => setSearchVisible(!searchVisible)}
      allowBulkEdit
      validationConfigPath={codeTypePath}
      isLiveScrubberProfile={isLiveScrubberProfile}
    />
  );
};

export default ConfigurationDictionaryTable;
