//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Application from "eai-configurator-ui/components/Application";
import ConfigurationDashboard from "eai-configurator-ui/components/configuration/ConfigurationDashboard";
import SaveConfigurationButton from "eai-configurator-ui/components/configuration/buttons/SaveConfigurationButton";
import AccountButton from "eai-configurator-ui/components/header/buttons/AccountButton";
import HelpButton from "eai-configurator-ui/components/header/buttons/HelpButton";
import MessagesButton from "eai-configurator-ui/components/header/buttons/MessagesButton";
import ConfigurationProfileButton from "eai-configurator-ui/components/configuration/buttons/ConfigurationProfileButton";
import {HashRouter as Router} from "react-router-dom";
import PatternMatching from "src/components/scrubber/pattern/PatternMatching";
import ScrubberWelcomeOverview from "src/components/scrubber/ScrubberWelcomeOverview";
import GeneralSettings from "src/components/scrubber/general/GeneralSettings";
import GlobalCalendarRolldown from "src/components/scrubber/calendar/GlobalCalendarRolldown";
import ProjectCalendarPage from "src/components/scrubber/calendar/ProjectCalendarPage";
import ResourceCalendarRemapping from "src/components/scrubber/calendar/ResourceCalendarRemapping";
import CodeTypes from "src/components/scrubber/code/CodeTypes";
import RequiredUdfTypes from "src/components/scrubber/udf/RequiredUdfTypes";
import CostAccount from "src/components/scrubber/cost_account/CostAccount";
import SimpleScrub from "src/components/scrubber/simple_scrub/SimpleScrub";
import UnitsOfMeasure from "src/components/scrubber/units_of_measure/UnitsOfMeasure";
import Resource from "src/components/scrubber/resource/Resource";
import OBS from "src/components/scrubber/obs/Obs";
import Roles from "src/components/scrubber/role/Roles";
import ScrubFilesButton from "src/components/scrubber/buttons/ScrubFilesButton";
import ImportFilesButton from "src/components/scrubber/buttons/ImportFilesButton";
import ManageCompaniesButton from "src/components/scrubber/buttons/ManageCompaniesButton";
import ScrubberManageUsersButton from "src/components/scrubber/buttons/ManageUsersButton";
import ScrubberManageUsersDialog from "src/components/scrubber/dialog/ManageUsersDialog";
import ManageCompaniesDialog from "src/components/scrubber/dialog/ManageCompaniesDialog";
import ScrubberDefinitionPermission from "src/components/scrubber/dialog/ScrubberDefinitionPermission";
import Login from "eai-configurator-ui/components//login/Login";
import SignUpButton from "src/components/scrubber/buttons/SignUpButton";
import {useConfigProfile} from "eai-configurator-ui/components/configuration/profile/ConfigurationProfile";
import ErrorMessageDialog from "eai-configurator-ui/components/configuration/dialog/ErrorMessageDialog";
import * as Utils from "eai-configurator-ui/components/Utils";
import {useSessionInfo} from "eai-configurator-ui/components/SessionInfo";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ScrubbedFilesTable from "src/components/scrubber/table/ScrubbedFilesTable";
import LastImportedProjectButton from "src/components/scrubber/buttons/LastImportedProjectButton";

function ScrubberApplication() {
  const [renderFlag, setRenderFlag] = React.useState(false);
  const [manageUsersDialogOpen, setManageUsersDialogOpen] = React.useState(
    false
  );
  const [
    manageCompaniesDialogOpen,
    setManageCompaniesDialogOpen,
  ] = React.useState(false);

  const [configProfile] = useConfigProfile();
  const [scrubberProfile, setScrubberProfile] = React.useState(null);
  const [fullScrubber, setFullScrubber] = React.useState(false);
  const [sessionInfo] = useSessionInfo();
  const [lastImportedProjectInfo, setLastImportedProjectInfo] = React.useState("None");

  React.useEffect(() => {
    Utils.fetchData("scrubber/profile", null, false, false)
      .then((response) => response.text())
      .then((data) => {
        setScrubberProfile(data);
      })
      .catch(function (error) {
        setScrubberProfile("error");
      });
  }, []);

  React.useEffect(() => {
    Utils.fetchData("scrubber/fullScrubber", null, false, false)
      .then((response) => response.text())
      .then((data) => {
        setFullScrubber(data === "true");
      })
      .catch(function (error) {
        setFullScrubber(false);
      });
  }, [sessionInfo]);

  if (scrubberProfile === null) {
    // Scrubber profile is set to null until the fetch completes.
    // Until then, the page will be blank/white for a brief moment.
    return null;
  } else if (scrubberProfile !== "live" && scrubberProfile !== "file") {
    return (
      <ErrorMessageDialog
        title={"Application not available"}
        description={"Ensure the application is up and running."}
        open
      />
    );
  }
  const isLiveProfile = scrubberProfile === "live";
  const isFileProfile = !isLiveProfile;

  // const ManageUsersButton = isLiveProfile
  //   ? CloudApiManageUsersButton
  //   : ScrubberManageUsersButton;
  // const ManageUsersDialog = isLiveProfile
  //   ? CloudApiManageUsersDialog
  //   : ScrubberManageUsersDialog;

  const ManageUsersButton = ScrubberManageUsersButton;
  const ManageUsersDialog = ScrubberManageUsersDialog;

  const accountButtons = [];
  if (isFileProfile && sessionInfo && sessionInfo.roles.includes("admin")) {
    accountButtons.unshift(
      <ManageCompaniesButton
        handleClick={() => setManageCompaniesDialogOpen(true)}
      />
    );
  }
  if (sessionInfo && sessionInfo.roles.includes("company_admin")) {
    accountButtons.unshift(
      <ManageUsersButton handleClick={() => setManageUsersDialogOpen(true)}/>
    );
  }

  // if (isFileProfile) {
  //   if (sessionInfo && sessionInfo.roles.includes("admin")) {
  //     accountButtons.unshift(
  //       <ManageCompaniesButton
  //         handleClick={() => setManageCompaniesDialogOpen(true)}
  //       />
  //     );
  //   } else if (sessionInfo && sessionInfo.roles.includes("company_admin")) {
  //     accountButtons.unshift(
  //       <ManageUsersButton handleClick={() => setManageUsersDialogOpen(true)}/>
  //     );
  //   }
  // } else {
  //   accountButtons.unshift(
  //     <ManageUsersButton handleClick={() => setManageUsersDialogOpen(true)} />
  //   );
  // }
  return (
    <Router>
      <Application
        login={
          <Login
            useDatabase={isLiveProfile}
            signupButton={isFileProfile ? <SignUpButton/> : null}
            copyrightYears={"2018-2022"}
            appName={"P6-Scrubber"}
          />
        }
        application={{
          routes: [
            {
              path: "/scrubber",
              component: (
                <ConfigurationDashboard
                  navigation={[
                    {
                      section: "Results",
                      label: "Tasks",
                      icon: <AssignmentTurnedInIcon/>,
                      component: <ScrubbedFilesTable alwaysDisplay={true}/>,
                    },
                    {
                      section: "Definitions",
                      label: "General",
                      icon: "settings",
                      component: <GeneralSettings path="scrubber:general"/>,
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Projects/WBS",
                      icon: "folder",
                      subNavigation: [
                        {
                          label: "ID/Name",
                          icon: "settings",
                          component: (
                            <PatternMatching path="scrubber:patternProject"/>
                          ),
                        },
                        {
                          label: "Codes",
                          icon: "label",
                          component: (
                            <CodeTypes
                              key={renderFlag}
                              path="scrubber:codesProject"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          section: "Definitions",
                          label: "UDFs",
                          icon: "book",
                          component: (
                            <RequiredUdfTypes
                              path="scrubber:udfsProject"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                      ],
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Activities",
                      icon: "assignment",
                      subNavigation: [
                        {
                          label: "ID/Name",
                          icon: "settings",
                          component: (
                            <PatternMatching path="scrubber:patternActivity"/>
                          ),
                        },
                        {
                          section: "Definitions",
                          label: "Codes",
                          icon: "label",
                          component: (
                            <CodeTypes
                              path="scrubber:codesActivity"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          section: "Definitions",
                          label: "UDFs",
                          icon: "book",
                          component: (
                            <RequiredUdfTypes
                              path="scrubber:udfsActivity"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                      ],
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Resources",
                      icon: "person_pin",
                      subNavigation: [
                        {
                          label: "Remapping",
                          icon: "label",
                          component: (
                            <Resource
                              path="scrubber:resource"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          label: "Codes",
                          icon: "label",
                          component: (
                            <CodeTypes
                              path="scrubber:codesResource"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          label: "UDFs",
                          icon: "book",
                          component: (
                            <RequiredUdfTypes
                              path="scrubber:udfsResource"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          label: "Units Of Measure",
                          icon: "timer",
                          component: (
                            <UnitsOfMeasure
                              path="scrubber:unitsOfMeasure"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                      ],
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Calendars",
                      icon: "calendar_today",
                      subNavigation: [
                        {
                          label: "Global",
                          icon: "timer",
                          component: (
                            <GlobalCalendarRolldown
                              path="scrubber:calendar:global"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          label: "Project",
                          icon: "timer",
                          component: (
                            <ProjectCalendarPage
                              path="scrubber:calendar"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                        {
                          label: "Resource",
                          icon: "timer",
                          component: (
                            <ResourceCalendarRemapping
                              path="scrubber:calendar:resource"
                              isLiveScrubberProfile={isLiveProfile}
                            />
                          ),
                        },
                      ],
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Roles",
                      icon: "person",
                      component: (
                        <Roles
                          path="scrubber:role"
                          isLiveScrubberProfile={isLiveProfile}
                        />
                      ),
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "Cost Accounts",
                      icon: "account_balance",
                      component: (
                        <CostAccount
                          path="scrubber:costAccount"
                          isLiveScrubberProfile={isLiveProfile}
                        />
                      ),
                    },
                    fullScrubber && {
                      section: "Definitions",
                      label: "OBS",
                      icon: "folder",
                      component: (
                        <OBS
                          path="scrubber:obs"
                          isLiveScrubberProfile={isLiveProfile}
                        />
                      ),
                    },
                    {
                      section: "Definitions",
                      label: "Simple Scrub",
                      icon: "post_add",
                      component: (
                        <SimpleScrub
                          path="scrubber:simplePrefix"
                          isLiveScrubberProfile={isLiveProfile}
                        />
                      ),
                    },
                  ].filter(function (el) {
                    return el != null && el !== false;
                  })}
                  welcomeContent={{
                    label: "Welcome",
                    component: <ScrubberWelcomeOverview/>,
                    configuration: "scrubber",
                  }}
                  headerButtons={[
                    <ConfigurationProfileButton profile={configProfile}/>,
                    <LastImportedProjectButton lastImportedProjectInfo={lastImportedProjectInfo}/>,
                    <MessagesButton/>,
                    <HelpButton/>,
                    <AccountButton buttons={accountButtons}/>,
                  ]}
                  actionButtons={[
                    <ImportFilesButton
                      onApply={(lastImportedProjects) => {
                        setRenderFlag(!renderFlag);
                        setLastImportedProjectInfo(lastImportedProjects);
                      }
                      }
                    />,
                    <ScrubFilesButton/>,
                    <SaveConfigurationButton
                      label="Save & Load Definitions"
                      profileLabel={"Scrubber Definition"}
                      PermissionConfigurationComponent={ScrubberDefinitionPermission}
                    />,
                  ]}
                />
              ),
            },
          ],
          defaultRoute: "scrubber",
        }}
      >
        <ManageUsersDialog
          open={manageUsersDialogOpen}
          handleClose={() => setManageUsersDialogOpen(false)}
          isLiveProfile={isLiveProfile}
          isFileProfile={isFileProfile}
        />
        <ManageCompaniesDialog
          open={manageCompaniesDialogOpen}
          handleClose={() => setManageCompaniesDialogOpen(false)}
          isLiveProfile={isLiveProfile}
          isFileProfile={isFileProfile}
        />
      </Application>
    </Router>
  );
}

export default ScrubberApplication;
