//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const PatternAnyNumberCustomizationDialog = ({ open, handleApplyChanges }) => {
  const [count, setCount] = useState(1);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="pattern-dialog">Any Number (0-9)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Summary: The pattern will match{" "}
          <b>{count <= 0 ? "unlimited number of" : count}</b> digit
          {count === 1 ? "" : "s"} ranging from <b>0-9</b>
        </DialogContentText>
        <p />
        <TextField
          size="medium"
          label={"How many digits?"}
          type="number"
          variant="outlined"
          value={count}
          onChange={(event) => setCount(Math.max(0, event.target.value))}
          id="count"
          helperText={"Set to zero for unlimited"}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleApplyChanges(count)}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PatternAnyNumberCustomizationDialog;
