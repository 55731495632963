//
// Copyright 2022 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//

import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const lightColor = "rgba(255, 255, 255, 0.7)";
const styles = (theme) => ({
  button: {
    borderColor: lightColor,
  },
});
const LastImportedProjectButton = ({classes, lastImportedProjectInfo}) => {
  return (
    <Button
      className={classes.button}
      variant="outlined"
      color="inherit"
      size="small"
    >
      Last Imported Project(s): {lastImportedProjectInfo}
    </Button>
  );
};
export default withStyles(styles)(LastImportedProjectButton);
