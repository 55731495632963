//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import Divider from "@material-ui/core/Divider";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationUDFTable from "src/components/scrubber/udf/ConfigurationUDFTable";
import ConfigurationCheckBoxGroup from "eai-configurator-ui/components/configuration/base/ConfigurationCheckBoxGroup";

const RequiredUdfTypes = ({ path, isLiveScrubberProfile }) => {
  const subjectArea = path.slice(path.lastIndexOf("udfs") + "udfs".length);
  return (
    <React.Fragment>
      <Label title="UDF Types Validation" />
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <Divider light style={{ marginTop: 8 }} />
      <p />
      <ConfigurationCheckBoxGroup path={path + ":scrubbedSubjectAreas"} />
      <p />
      <ConfigurationUDFTable
        path={path + ":udfTypes"}
        subjectArea={subjectArea}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default RequiredUdfTypes;
