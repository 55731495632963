//
// Copyright 2021 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import ConfigurationUnitsOfMeasureTable from "src/components/scrubber/units_of_measure/ConfigurationUnitsOfMeasureTable";

const UnitsOfMeasure = ({ path, isLiveScrubberProfile }) => {
  return (
    <React.Fragment>
      <Label title="Units Of Measure Remapping" />
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <p />
      <ConfigurationUnitsOfMeasureTable
        path={path + ":unitsOfMeasure"}
        isLiveScrubberProfile={isLiveScrubberProfile}
      />
    </React.Fragment>
  );
};
export default UnitsOfMeasure;
