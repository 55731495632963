//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useDropzone } from "react-dropzone";
import Box from "@material-ui/core/Box";
import PlayIcon from "@material-ui/icons/PlayArrow";
import FilesIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: 4,
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
}));

const ScrubberUpload = ({
  disabled,
  handleSubmit,
  submitButtonLabel,
  dropzoneOptions,
  isImportRequestPending,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { getRootProps, getInputProps, open } = useDropzone({
    // accept: 'image/jpeg, image/png'
    onDrop: (files) => setSelectedFiles(files),
    ...dropzoneOptions,
  });

  const onSubmit = () => {
    if (handleSubmit(selectedFiles)) {
      setSelectedFiles([]);
    }
  };

  const selectedFilesItems = selectedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const classes = useStyles();
  return (
    <React.Fragment>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <ul>{selectedFilesItems}</ul>
      </aside>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<FilesIcon />}
            onClick={open}
          >
            Choose File(s)
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={
              isImportRequestPending ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <PlayIcon />
              )
            }
            disabled={selectedFilesItems.length === 0 || disabled}
            onClick={onSubmit}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );
};
export default ScrubberUpload;
