//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import IndicatorInfoItem from "src/components/scrubber/info/IndicatorInfoItem";
import InfoPanel from "src/components/scrubber/info/InfoPanel";
import PatternDefinitionPanel from "src/components/scrubber/pattern/PatternDefinitionPanel";
import Label from "eai-configurator-ui/components/base/Label";
import ConfigurationSwitch from "eai-configurator-ui/components/configuration/base/ConfigurationSwitch";
import InfoBox from "eai-configurator-ui/components/alert/InfoBox";

const PatternInfoPanel = ({ unitName }) => {
  return (
    <InfoPanel
      title={"How to define a pattern?"}
      items={[
        <IndicatorInfoItem
          title={
            <div>
              There are several ways to define a pattern ranging from simple to
              very advanced.
              <p />
              <ul>
                <li key="contains">
                  <b>Contains</b> - Includes all {unitName} containing the
                  provided text
                </li>
                <li key="starsWith">
                  <b>Starts With</b> - Includes all {unitName} that start with
                  the provided text
                </li>
                <li key="endsWith">
                  <b>Ends With</b> - Includes all {unitName} that end with the
                  provided text
                </li>
                <li key="simplePattern">
                  <b>Simple Pattern</b> - Provides a way to define a sequence of
                  simple patterns for matching
                </li>
                <li key="advancedPattern">
                  <b>Advanced Pattern</b> - Provides a way to define more complex
                  patterns - Emerald may need to assist with this option
                </li>
              </ul>
            </div>
          }
        />,
      ]}
    />
  );
};
const PatternMatching = ({ path }) => {
  const scope = path.slice(path.lastIndexOf("pattern") + 7);

  const indicators = [
    <IndicatorInfoItem
      title={
        <div>
          {`${scope} Id UDF Indicator: `}
          <b>{`P6SCRUB: ${scope} Id`} </b>
          <p />
          {`${scope} Name UDF Indicator: `}
          <b>{`P6SCRUB: ${scope} Name`} </b>
        </div>
      }
      green={"Pattern matches"}
      red={"Pattern does not match"}
    />,
  ];
  if (scope === "Activity") {
    indicators.push(
      <IndicatorInfoItem
        title={
          <div>
            {`${scope} Id UDF Indicator: `}
            <b>{`P6SCRUB: ${scope} Id`} </b>
            <p />
            {`${scope} Name UDF Indicator: `}
            <b>{`P6SCRUB: ${scope} Name`} </b>
          </div>
        }
        green={"Pattern matches on a given Activity"}
        red={"Pattern does not match on a given Activity"}
      />
    );
  }
  const infoMessage =
    "Perform pattern matching on " +
    scope +
    " fields and report results in UDF Indicators";
  return (
    <React.Fragment>
      <React.Fragment>
        <Label title="General Settings" />
        <InfoBox title={"How does it work?"}>
          {infoMessage}
          <br />
          <br />
          <PatternInfoPanel unitName="IDs/names" />
          <InfoPanel title={"Result reporting"} items={indicators} />
        </InfoBox>
      </React.Fragment>
      <p />
      <ConfigurationSwitch path={path + ":enabled"} />
      <PatternDefinitionPanel
        key={path + " id"}
        label={`Define ${scope} ID Pattern`}
        path={`${path}:idPattern`}
      />
      <PatternDefinitionPanel
        key={path + " name"}
        label={`Define ${scope} Name Pattern`}
        path={`${path}:namePattern`}
      />
      <p />
    </React.Fragment>
  );
};
export { PatternInfoPanel };
export default PatternMatching;
