//
// Copyright 2020 Emerald Associates, Inc.  All Rights Reserved.
//
// Use of this file other than by Emerald Associates, Inc. is forbidden
// unless otherwise authorized by a separate written license agreement.
//
// $Id$
//
import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ManageCompaniesTable from "src/components/scrubber/table/ManageCompaniesTable";
import CloseableDialog from "eai-configurator-ui/components/configuration/dialog/CloseableDialog";
import CompanyEditDialog from "src/components/scrubber/dialog/CompanyEditDialog";
import ManageUsersDialog from "src/components/scrubber/dialog/ManageUsersDialog";

const ManageCompaniesDialog = ({open, handleClose, isFileProfile, isLiveProfile}) => {
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [companyPendingEdit, setCompanyPendingEdit] = React.useState(false);
  const [isEditingUsers, setIsEditingUsers] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [tableRefreshCounter, setTableRefreshCounter] = React.useState(0);

  const handleCloseEditedProfile = () => {
    setCompanyPendingEdit(false);
  };

  const handleEditCompany = (company) => {
    setSelectedCompany(company);
    setCompanyPendingEdit(true);
  };

  const handleEditUsers = (company) => {
    setSelectedCompany(company);
    setIsEditingUsers(true);
  };

  const handleAddCompany = () => {
    setSelectedCompany(null);
    setCompanyPendingEdit(true);
  };

  const handleApplyCompanyChanges = (company) => {
    // Refresh the table which will issue a new fetch and update its contents
    setTableRefreshCounter(tableRefreshCounter + 1);
  };

  return (
    <span>
      {companyPendingEdit && (
        <CompanyEditDialog
          company={selectedCompany}
          handleClose={handleCloseEditedProfile}
          handleSuccess={handleApplyCompanyChanges}
        />
      )}
      {selectedCompany && isEditingUsers && (
        <ManageUsersDialog
          companyId={selectedCompany.id}
          open={isEditingUsers}
          handleClose={() => setIsEditingUsers(false)}
          handleSuccess={handleApplyCompanyChanges}
          isLiveProfile={isLiveProfile}
          isFileProfile={isFileProfile}
        />
      )}
      {alertMessage && (
        <Portal>
          <Snackbar
            open={alertMessage}
            autoHideDuration={6000}
            onClose={() => setAlertMessage(null)}
          >
            <Alert
              onClose={() => setAlertMessage(null)}
              severity={
                alertMessage && alertMessage.success ? "success" : "error"
              }
            >
              {alertMessage && (alertMessage.message || "Unknown error")}
            </Alert>
          </Snackbar>
        </Portal>
      )}
      <CloseableDialog
        open={open}
        title={"Manage Companies"}
        maxWidth="md"
        fullWidth
        handleClose={handleClose}
      >
        <DialogContent dividers>
          <ManageCompaniesTable
            refreshCounter={tableRefreshCounter}
            setAlertMessage={setAlertMessage}
            handleEditCompany={handleEditCompany}
            handleEditUsers={handleEditUsers}
            isLiveProfile={isLiveProfile}
            isFileProfile={isFileProfile}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleAddCompany}>
            Add Company
          </Button>
        </DialogActions>
      </CloseableDialog>
    </span>
  );
};
export default ManageCompaniesDialog;
